<!-- <div layout="column" layout-align="center center">
   <mat-icon *ngIf="message.isSuccess" class="success large-font">check_circle</mat-icon>
   <mat-icon *ngIf="!message.isSuccess" class="failure large-font">warning</mat-icon>
   <div>
     <p>{{message.text}}</p>
   </div>

</div> -->


<div layout="column" layout-align="center center">
  <mat-icon *ngIf="message.isSuccess" class="success large-font margin-20">check_circle</mat-icon>
  <mat-icon *ngIf="!message.isSuccess" class="failure large-font margin-20">warning</mat-icon> 
  <span class="responseHeader margin-10" >
     <span *ngIf="message.isSuccess">Request Submitted Successfully</span>
     <span *ngIf="!message.isSuccess">Some error occured!</span>
  </span>
  <span class="responseMessage margin-20">
    {{message.text}}
  </span>
  
  <button mat-raised-button (click)="closeDialog()" class="text-upper margin-20">OK</button>
</div>

