<td-layout-card-over cardWidth="75" class="imc box-form" >
   <div >
    <mat-card-header>
        <mat-card-title>
            Schedule/Reschedule Software Upgrade
        </mat-card-title>
    </mat-card-header>
    <mat-divider></mat-divider>
    <mat-card-content>
             <div *tdLoading="'releaseLoad'; mode:'indeterminate'; type:'circle'; strategy:'overlay'; color:'primary'">
                <td-steps mode="horizontal">
                        <td-step #step1 label="Release Information" [state]="stateStep1" [active]="activeIndex === 0" (activated)="activeIndex = 0">
                            <span *ngIf="release">Details of software update release are as mentioned below.</span>
                            <mc-release-detail [releases]="releases" [release]="release" (selectedReleaseDetailsEvent)="setSelectedRelease($event)" #step1Form></mc-release-detail>
                            <p class="form-error">{{step1ErrorMessage}} </p>
                        </td-step>
                        <td-step #step2 label="Schedule/Reschedule Maintenance" [active]="activeIndex === 1" [state]="stateStep2" (activated)="activeStep1Event()" [disabled]="disableStep4">
                            <mc-schedule-sites [customerList]="customerList"  (emitSelectedSites)="getAllSitesForSelctedCustomer($event)" [sites]="sites" [isReschedule]="isReschedule"  #step2form></mc-schedule-sites>
                            <p class="form-error">{{step2ErrorMessage}} </p>
                        </td-step>
                        <td-step #step3 label="Confirm Details" [active]="activeIndex === 2" [state]="stateStep3" [disabled]="disableStep4" (activated)="activeStep2Event()">
                            <mc-confirm-upgrade #step3form></mc-confirm-upgrade>
                           
                        </td-step>
                        <!-- <td-step #step4 label="Success" [active]="activeIndex === 3" [state]="stateStep4"  (activated)="activeStep4Event()" [disabled]="disableStep4">
                            <app-success-failure [message]="successOrFailureMessage" #step4form></app-success-failure>
                           
                        </td-step> -->
                    </td-steps>
                    </div>
               
    </mat-card-content>
    <mat-divider></mat-divider>
    <mat-card-actions>
            <div layout="row" flex="100" layout-align="start start">
                    <div layout="column" flex="20">
                        <div layout="row" layout-align="start">
                            <ng-template style="float: none;" [ngIf]="activeIndex !== 0">
                                <button mat-button class="text-upper" (click)="activeIndex = activeIndex-1">Back</button>
                            </ng-template>
                        </div>
                    </div>
                    <div layout="column" flex="80">
                        <div layout="row" layout-align="end">
                            <!-- <button mat-button (click)="goBackToDashboard()" class="text-upper">Cancel</button> -->
                            <button mat-button color="primary" class="text-upper" *ngIf="activeIndex < 2" (click)="next()">Continue</button>
                            <button mat-button color="primary" class="text-upper" *ngIf="activeIndex === 2" [disabled]="!atleastOneSiteScheduled"
                                (click)="upgrade()">Submit</button>
                        </div>
                    </div>
                </div>

    </mat-card-actions>
</div>

</td-layout-card-over>


<!-- 
<div class="modal-dialog" *tdLoading="'schedules.load'; mode:'indeterminate'; type:'circle'; strategy:'overlay'; color:'accent'">
<h4 mat-dialog-title>
    Schedule Software Upgrade
</h4>
<mat-dialog-content>
    <td-steps mode="horizontal">
        <td-step #step1 label="Release Information" [state]="stateStep1" [active]="activeIndex === 0" (activated)="activeIndex = 0">
            Details of software update release are as mentioned below.
            <mc-release-detail [release]="release"></mc-release-detail>
        </td-step>
        <td-step #step2 label="Schedule Maintenance" [active]="activeIndex === 1" [state]="stateStep2" (activated)="activeStep2Event()">
            <mc-schedule-sites [sites]="sites" #step2form></mc-schedule-sites>
            <p class="form-error">{{step2ErrorMessage}} </p>
        </td-step>
        <td-step #step3 label="Confirm Details" [active]="activeIndex === 2" (activated)="activeStep3Event()">
            <mc-confirm-upgrade #step3form></mc-confirm-upgrade>
        </td-step>
    </td-steps>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions>
    <div layout="row" flex="100" layout-align="start start">
        <div layout="column" flex="20">
            <div layout="row" layout-align="start">
                <ng-template style="float: none;" [ngIf]="activeIndex !== 0">
                    <button mat-button class="text-upper" (click)="activeIndex = activeIndex-1">Back</button>
                </ng-template>
            </div>
        </div>
        <div layout="column" flex="80">
            <div layout="row" layout-align="end">
                <button mat-button mat-dialog-close class="text-upper">Cancel</button>
                <button mat-button color="primary" class="text-upper" *ngIf="activeIndex !== 2" (click)="next()">Continue</button>
                <button mat-button color="primary" class="text-upper" *ngIf="activeIndex === 2" [disabled]="!atleastOneSiteScheduled"
                    (click)="upgrade()">Submit</button>
            </div>
        </div>
    </div>
</mat-dialog-actions>
</div> -->


