import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatSnackBar } from '@angular/material';
import { GetJobDetailsService } from 'src/app/services/job-details.service';
import { TdLoadingService, TdDialogService } from '@covalent/core';
import { AddFailureReasonDialogComponent } from 'src/app/shared/dialogs/add-failure-reason-dialog/add-failure-reason-dialog.component';
import { FailureAnalysisService } from 'src/app/services/failure-analysis.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-failure-analysis',
  templateUrl: './failure-analysis.component.html',
  styleUrls: ['./failure-analysis.component.css']
})
export class FailureAnalysisComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  ELEMENT_DATA = [];
  dataSource;
  dialogRef;
  dataFound = false;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  displayedColumns = ['id',
    'product',
    'siteName',
    'customer_name',
    'purpose',
    'save'
  ];

  constructor(private jobDetailsService: GetJobDetailsService,
    private tdLoadingService: TdLoadingService,
    private tdDialogService: TdDialogService,
    private failureService: FailureAnalysisService,
    private router: Router) {
    this.dataSource = new MatTableDataSource(this.ELEMENT_DATA)
  }

  ngOnInit() {
    if(localStorage.getItem('isLoggedIn') === 'true'){
    this.tdLoadingService.register('failureLoader');
    this.jobDetailsService.jobDetailsRecievedEvent.subscribe(() => {
      this.tdLoadingService.resolve('failureLoader');
      this.dataFound = false;
      if (this.jobDetailsService.getAllJobDetails().length) {
        this.dataSource.data = this.jobDetailsService.getAllJobDetails();
        this.dataFound = true;
      }
    })
    this.failureService.fireLoaderOnApiCall.subscribe(() => {
      this.tdLoadingService.register('failureLoader');
    })
   }else{
    this.router.navigate(['/']);
   }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  saveFailureReason(row) {
    this.dialogRef = this.tdDialogService.open(AddFailureReasonDialogComponent, {
      data: row,
      width: '500px',
      disableClose: true
    });
  }
}
