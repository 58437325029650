import { Injectable } from "@angular/core";
import { AppConfig } from "../app.config";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { FetchAccessTokenService } from "./fetch-access-token.service";
import { catchError } from "rxjs/operators";
import { throwError, Subject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class FailureAnalysisService {
  fireLoaderOnApiCall = new Subject();
  constructor(
    private httpClient: HttpClient,
    private appConfig: AppConfig,
    private getAccessToken: FetchAccessTokenService
  ) {}

  getFailureByJobId(jobId) {
    let failureUrl =
      this.appConfig.getConfig("jobFailureUrl") + "/" + jobId + "/failures";
    let accessToken = this.getAccessToken.getAccessToken();
    let headers = new HttpHeaders({ Authorization: accessToken });

    return this.httpClient.get(failureUrl, {
      headers: headers,
      withCredentials: false
    });
  }

  saveFailureByJobId(jobId, payload) {
    let failureUrl =
      this.appConfig.getConfig("jobFailureUrl") + "/" + jobId + "/failures";
    let accessToken = this.getAccessToken.getAccessToken();
    let headers = new HttpHeaders({ Authorization: accessToken });

    return this.httpClient.post(failureUrl, payload, {
      headers: headers,
      withCredentials: false
    });
  }
}
