<div  flex layout-align-gt-sm="center start" layout-gt-sm="row" >
  <div flex-gt-lg="40" flex-lg="45" flex-md="55">
    <mat-card class="mat-card push-top-lg" *tdLoading="'loginload'; mode:'indeterminate'; type:'circle'; strategy:'overlay'; color:'primary'">
      <form name="form" (ngSubmit)="f.form.valid && onSubmit(f)" #f="ngForm" novalidate>
        <mat-card-title>
          <div layout="row" layout-align="start center">
            <mat-icon class="mat-icon mat-icon-logo" role="img" svgIcon="assets:teradata" aria-hidden="true">

            </mat-icon>
            <span class="pad-left-sm mat-title push-bottom-none">Intellicloud</span>
          </div>
        </mat-card-title>
        <mat-divider></mat-divider>
        <mat-card-content>

          <div layout="column" class="form-group">
            
            <mat-form-field flex>
              <input  required  name="userName" #userName="ngModel" matInput placeholder="your username" class="form-control" ngModel>
            </mat-form-field>
            <mat-form-field flex>
              <input required  type="password" #password="ngModel" name="password" matInput placeholder="password" class="form-control" ngModel>
            </mat-form-field>
          </div>
          <td-message *ngIf="showError"  sublabel="Incorrect username/password" color="warn" icon="error"></td-message>


        </mat-card-content>
        <mat-divider></mat-divider>
        <mat-card-actions>
          <div layout="row">
            <button [disabled]="f.invalid"  type="submit" mat-raised-button flex color="accent" class="mat-raised-button mat-accent textUpper">Sign IN</button>
          </div>
        </mat-card-actions>
      </form>
    </mat-card>
  </div>
</div>
