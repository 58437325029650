import { Injectable, OnInit, EventEmitter } from "@angular/core";
import { AppConfig } from "../app.config";
import { UpgradeSchedulerService } from "./upgrade-scheduler.service";
import { FetchAccessTokenService } from "./fetch-access-token.service";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class CustomerAndSiteService {
  private accessToken;
  private customerAdSiteList;
  public emitCustomerInfo = new EventEmitter();
  constructor(
    private appConfig: AppConfig,
    private fetchAccessToken: FetchAccessTokenService,
    private httpClient: HttpClient
  ) {}

  // getAllCustomers(){
  //      let customerServiceURL = this.appConfig.getConfig('customerServiceUrl');
  //      return this.httpClient.get(customerServiceURL,{
  //        headers:{'Authorization':'Bearer '+this.fetchAccessToken.getAccessToken()}
  //      }).pipe(map((responses:any)=>{
  //         let parsedResponse=  responses.result.reduce((acc,currVal)=>{
  //               let tempObj={customerId:'',customerName:'',cloudId:''};
  //               tempObj.customerId=currVal.customerId;
  //               tempObj.customerName=currVal.name;
  //               tempObj.cloudId=currVal.cloudId? currVal.cloudId:'';
  //               acc.push(tempObj);
  //               return acc;
  //          },[]);

  //          parsedResponse.sort(function(a, b){
  //           if(a.customerName < b.customerName) { return -1; }
  //           if(a.customerName > b.customerName) { return 1; }
  //           return 0;
  //       });

  //          return parsedResponse;
  //      }))
  // }

  // getAllSites(customerId) {
  //   let siteUrl= this.appConfig.getConfig('customerServiceUrl')+'/'+customerId+'/sites'
  //   return this.httpClient.get(siteUrl,{
  //     headers:{'Authorization':this.fetchAccessToken.getAccessToken()}
  //   }).pipe(map((response:any)=>{
  //      let parsedResponse = response.result.reduce((acc,currVal)=>{
  //       let tempObj = { name: '', purpose: '', siteId: '', disableEdit: false};
  //       tempObj.siteId=currVal.siteId;
  //       tempObj.name=currVal.siteName;
  //       tempObj.purpose=currVal.purpose;
  //       acc.push(tempObj);
  //       return acc;
  //      },[])
  //      return parsedResponse;
  //   }))
  // }
  setCustomerList(customerAdSiteList) {
    this.customerAdSiteList = customerAdSiteList;
  }

  getCustomersByPlatform(data: any, platform) {
    let tempObj = {};
    for (let key in data) {
      data[key].sites.forEach(s => {
        if (s.platform == platform) {
          tempObj[data[key].customer.customerName] = data[key].customer;
        }
      });
    }
    return Object.values(tempObj).sort((a: any, b: any) => {
      var textA = a.customerName.toUpperCase();
      var textB = b.customerName.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
  }

  getCustomerList() {
    return this.customerAdSiteList;
  }
  getAllSites() {
    let siteUrl = this.appConfig.getConfig("siteServiceUrl");
    return this.httpClient
      .get(siteUrl, {
        headers: { Authorization: this.fetchAccessToken.getAccessToken() }
      })
      .pipe(
        map((response: any) => {
          let parsedData = response.result.reduce((acc, currVal) => {
            if (currVal.customerName || currVal.customer) {
              if (currVal.customerId in acc) {
                let tmpObj = acc[currVal.customerId];
                let found = false;
                let tmpSiteObj = {
                  name: "",
                  purpose: "",
                  siteId: "",
                  disableEdit: false,
                  platform: "",
                  supportSiteId: ""
                };
                tmpObj.sites.forEach(site => {
                  if (site.name == currVal.siteName) {
                    found = true;
                  }
                });
                if (!found) {
                  tmpSiteObj.name = currVal.siteName;
                  tmpSiteObj.purpose = currVal.purpose;
                  tmpSiteObj.siteId = currVal.siteId;
                  tmpSiteObj.disableEdit = false;
                  tmpSiteObj.platform = currVal.cloudPlatform;
                  tmpSiteObj.supportSiteId = currVal.supportSiteId;
                  tmpObj.sites.push(tmpSiteObj);
                }
              } else {
                acc[currVal.customerId] = {
                  customer: { customerName: "", customerId: "", cloudId: "" },
                  sites: []
                };
                let tmpObj = acc[currVal.customerId];
                tmpObj.customer.customerName = currVal.customer
                  ? currVal.customer.name
                  : currVal.customerName;
                //tmpObj.customer.platform = currVal.cloudPlatform;
                tmpObj.customer.customerId = currVal.customerId;
                if (currVal.customer) {
                  tmpObj.customer.cloudId = currVal.customer.cloudId;
                } else if (currVal.customer_id) {
                  tmpObj.customer.cloudId = currVal.customer_id;
                } else {
                  tmpObj.customer.cloudId = currVal.cloudId;
                }
                let tmpSiteObj = {
                  name: "",
                  purpose: "",
                  siteId: "",
                  disableEdit: false,
                  platform: "",
                  supportSiteId: ""
                };
                tmpSiteObj.name = currVal.siteName;
                tmpSiteObj.purpose = currVal.purpose;
                tmpSiteObj.siteId = currVal.siteId;
                tmpSiteObj.platform = currVal.cloudPlatform;
                tmpSiteObj.disableEdit = false;
                tmpSiteObj.supportSiteId = currVal.supportSiteId;
                tmpObj.sites.push(tmpSiteObj);
              }
            }

            return acc;
          }, {});
          return parsedData;
        })
      );
  }
}
