<h3 mat-dialog-title *ngIf="!showMessage" class="dialog-title">Add/Edit Failure Reason and Category</h3>

<div *ngIf="!showMessage">
<div >
  <div mat-dialog-content
    *tdLoading="'failureReasonDialogLoader'; mode:'indeterminate'; type:'circle'; strategy:'overlay'; color:'primary'">
    <h4>Job Id: <b>{{data.id}} </b></h4>
    <div layout="column">
      <div class="field-container" layout="column" layout-align="center start">
        <mat-form-field class="full-width">
          <mat-label>Reason</mat-label>
          <textarea matInput [(ngModel)]="failureReason" ></textarea>
        </mat-form-field>
        <mat-form-field class="full-width">
          <mat-label>Category</mat-label>
          <mat-select [(ngModel)]="failureCategory">
            <mat-option *ngFor="let failure of failures" [value]="failure.value">
              {{failure.label}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="full-width">
          <mat-label>Jira/Incident Number</mat-label>
          <textarea matInput [(ngModel)]="jiraLink" ></textarea>
        </mat-form-field>
      </div>
    </div>
  </div>
</div>


<div mat-dialog-actions layout="row" layout-align="end center">
  <a flex="10" mat-button class="uppercase  " [disabled]="disableActionOnSubmit" (click)="closeDialog()">Cancel</a>
  <button flex="10" mat-button class="uppercase tc-td-orange-800 " [disabled]="!failureReason || !failureCategory || disableActionOnSubmit"
    (click)="submit()">Submit</button>

</div>
</div>

<div layout="column" layout-align="center center" *ngIf="showMessage">
  <mat-icon *ngIf="isSuccess" class="success large-font ">check_circle</mat-icon>
  <mat-icon *ngIf="!isSuccess" class="failure large-font ">warning</mat-icon>
  <span class="responseHeader ">
    <h4 *ngIf="isSuccess">Request Submitted Successfully</h4>
    <h4 *ngIf="!isSuccess">Some error occured</h4>
  </span>
  <span class="responseMessage ">
    {{message}} <b>{{data.id}}</b>
  </span>

  <button mat-button color="accent" (click)="closeDialog('loadAllData')" class="text-upper margin-20">OK</button>
</div>
