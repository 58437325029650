import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { MatIconRegistry } from "@angular/material";
import { NgForm } from "@angular/forms";
import { Route, Router } from "@angular/router";
import { TdLoadingService } from "@covalent/core";
import { FetchAccessTokenService } from "src/app/services/fetch-access-token.service";
import { CustomerAndSiteService } from "src/app/services/customer-and-site.service";
import { AppConfig } from "src/app/app.config";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";

@Component({
  selector: "app-upaas-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit {
  showError = false;
  constructor(
    private _iconRegistry: MatIconRegistry,
    private _domSanitizer: DomSanitizer,
    private router: Router,
    private tdLoading: TdLoadingService,
    private fetchAccessToken: FetchAccessTokenService,
    private customerAndSiteService: CustomerAndSiteService,
    private appConfig: AppConfig
  ) {
    // this is just mat icon registry to show teradata logo in header
    this._iconRegistry.addSvgIconInNamespace(
      "assets",
      "teradata",
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/icons/teradata-dark.svg"
      )
    );
  }

  ngOnInit() {
    //this.fetchAccessToken.emitLogIn = Observable.interval(15*60*1000);
  }

  onSubmit(f: NgForm) {
    this.tdLoading.register("loginload");
    this.fetchAccessToken
      .fetchAccessToken(f.value.userName, f.value.password)
      .subscribe(
        (data: any) => {
          this.fetchAccessToken.setAccessToken(data.access_token);
          let str = btoa(f.value.userName + "/" + f.value.password);
          localStorage.setItem("up", str);
          localStorage.setItem("isLoggedIn", "true");
          localStorage.setItem(
            "expires_in",
            new Date().getTime() + data.expires_in
          );
          this.tdLoading.resolve("loginload");
          this.fetchAccessToken.refreshToken();
          this.router.navigate(["/schedule"]);
        },
        err => {
          if (err.error && err.error.error == "invalid_grant") {
            this.showError = true;
          }
          this.tdLoading.resolve("loginload");
        }
      );
  }
}
