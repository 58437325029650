import { Component, Input, EventEmitter, Output, OnChanges } from "@angular/core";
import { UpgradeSchedulerService } from "../../services/upgrade-scheduler.service";
import {allProducts} from '../../shared/models/product.data';


@Component({
  selector: "mc-release-detail",
  templateUrl: "./release-detail.component.html",
  styleUrls: ["./release-detail.component.scss"]
})
export class ReleaseDetailComponent{

	@Input("releases")
	releases;

	@Input("release")
	release;
	selectedRelease='';
	allProducts;

@Output('selectedReleaseDetailsEvent') selectedReleaseDetailsEvent = new EventEmitter();

	constructor(private upgradeSchedulerService: UpgradeSchedulerService) {
        this.allProducts=allProducts;
	}

	resetRelease(){
		this.selectedRelease='';
	}


	getSelectedReleaseInformation(selectedRelease){
     this.selectedReleaseDetailsEvent.emit(selectedRelease);
	}
}
