import { Component, OnInit, ViewChild } from '@angular/core';
import { UpgradeSchedulerService } from 'src/app/services/upgrade-scheduler.service';
import { MatPaginator, MatSort, MatTableDataSource, MatSnackBar } from '@angular/material';
import { TdLoadingService, TdDialogService } from '@covalent/core';
import { finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FetchAccessTokenService } from 'src/app/services/fetch-access-token.service';

@Component({
  selector: 'app-scheduled-upgrades-history',
  templateUrl: './scheduled-upgrades-history.component.html',
  styleUrls: ['./scheduled-upgrades-history.component.css']
})
export class ScheduledUpgradesHistoryComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  ELEMENT_DATA = [];
  dataSource;
  dialogRef;


  displayedColumns = ['customerName',
    'siteName',
    'purpose',
    'releaseName',
    'scheduledOn',
    'actions'];

  constructor(private scheduleApi: UpgradeSchedulerService, private loadingService: TdLoadingService,
    private tdDialogService: TdDialogService,
    private snackBar: MatSnackBar,
    private router: Router,
    private fetchAccessToken : FetchAccessTokenService) {
    this.dataSource = new MatTableDataSource(this.ELEMENT_DATA)
  }

  ngOnInit() {
    if (localStorage.getItem('isLoggedIn') === 'false') {
      this.router.navigate(['/'])
    } else if(!this.fetchAccessToken.loginSubscription) {
      this.fetchAccessToken.refreshToken();    
      this.loadAllSchedules();
    }else{
      this.loadAllSchedules();
    }
  }

  initializeData(schedules) {
    this.ELEMENT_DATA = schedules;
    this.dataSource.data = this.ELEMENT_DATA;

  }

  loadAllSchedules() {
    this.loadingService.register('scheduleDetailsMainLoader');
    this.scheduleApi.getAllSchedules().pipe(finalize(() => {
    })).subscribe((schedules) => {
      //this.initializeData(schedules);
      if (schedules && schedules.length) {
        this.scheduleApi.getSiteAndCustomerInfoForAllSchedules(schedules).pipe(finalize(() => {
        })).subscribe((data) => {
          this.loadingService.resolve('scheduleDetailsMainLoader');
          this.initializeData(data);
        });
      } else {
        this.initializeData([]);
        this.loadingService.resolve('scheduleDetailsMainLoader');
      }
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  deleteSchedule(row) {
    this.tdDialogService.openConfirm({
      message: 'Do you want to cancel the selected schedule?',
      title: 'Confirm', //OPTIONAL, hides if not provided
      cancelButton: 'Disagree', //OPTIONAL, defaults to 'CANCEL'
      acceptButton: 'Agree', //OPTIONAL, defaults to 'ACCEPT'
      width: '500px', //OPTIONAL, defaults to 400px
    }).afterClosed().subscribe((accept: boolean) => {
      if (accept) {
        this.tdDialogService.closeAll();
        this.loadingService.register('deleteScheduleloader');
        this.scheduleApi.deleteSchedule(row.scheduleId).pipe(finalize(() => {
          this.loadingService.resolve('deleteScheduleloader');
          this.tdDialogService.closeAll();
        })).subscribe(() => {
          this.snackBar.open('Successfully cancelled the schedule! Fetching updated details.', null, {
            duration: 5000,
          });
          this.loadAllSchedules();
        }, () => {
          this.snackBar.open('could not cancel the schedule', null, {
            duration: 5000,
          });
        })
      } else {
        this.tdDialogService.closeAll();
      }
    });
  }

}
