<td-layout>
  <td-layout-nav>
    <div td-toolbar-content layout="row" layout-align="start center" flex>
      <mat-icon [routerLink]="['/']" class="mat-icon-logo cursor-pointer" svgIcon="assets:teradata"></mat-icon>
      <span [routerLink]="['/']" class="cursor-pointer">Intellicloud</span>
      <span flex></span>
      <div layout="row" layout-align="center center" class="margin-right">
        <button mat-icon-button class="margin-button" [routerLink]="['/job-details']">Job Details</button>
        <button mat-icon-button class="margin-right-35" [routerLink]="['/schedule']" >Schedule</button>
        <button mat-icon-button class="margin-right-35" [routerLink]="['/upgrade']" >Upgrade</button>
        <button mat-icon-button class="margin-right-114" [routerLink]="['/scheduled-upgrades']" >Scheduled-upgrades</button>
        <button mat-icon-button class="margin-right-40" [routerLink]="['/failure-reason-addition']" >Failure Analysis</button>
      </div> 
    </div>
    <div layout="column">
      <router-outlet></router-outlet>
    </div>
  </td-layout-nav>
</td-layout>
