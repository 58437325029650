import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { environment } from '../environments/environment';
import {map , catchError } from 'rxjs/operators';

@Injectable()
export class AppConfig {

    private config: object = {};
    // tslint:disable-next-line:no-unused-variable
    private env: object = null;

    constructor(private http: HttpClient) {

    }

    public getConfig(key: any) {
        return this.config[key];
    }

    public load() {
        return new Promise((resolve, reject) => {
            let request: any = null;
            request =   this.http.get('./assets/config/' + environment.name + '.json');

            if (request) {
                request.pipe(
                     catchError ( (error: any) => {
                        console.error('Error reading configuration file');
                        resolve(error);
                        return Observable.throw(error.json().error || 'Server error');
                    })
                ) .subscribe((responseData) => {
                        this.config = responseData;
                        resolve(true);
                    });
            } else {
                console.error('Config file is not valid');
                resolve(true);
            }
        });
    }
}

