import { Component, Input, Output, OnChanges, EventEmitter, ViewChild, OnInit } from "@angular/core";
import { FormControl, NgForm } from '@angular/forms';
import { CustomerAndSiteService } from "src/app/services/customer-and-site.service";
import { Observable } from "rxjs";
import { TdLoadingService } from "@covalent/core";
import { platforms } from 'src/app/shared/models/platform.data';
import { UpgradeSchedulerService } from "src/app/services/upgrade-scheduler.service";

@Component({
    selector: "mc-schedule-sites",
    templateUrl: "./schedule-sites.component.html",
    styleUrls: ["./schedule-sites.component.scss"]
})
export class ScheduleSitesComponent implements OnInit {
    @Input("sites")
    sites = [];
    @Input("isReschedule")
    isReschedule = false;
    @Input("isEditMode")
    isEditMode: boolean = true;
    @Input("customerList")
    customerList;
    platforms;
    customerListByPlatform;
    selectedPlatform;
    myControl = new FormControl();
    filteredOptions: Observable<string[]>;
    

    @Output("emitSelectedSites") emitSelectedSites = new EventEmitter();

    options: string[];
   
    @Output('updateSites') updateSites = new EventEmitter();
    minDate;
    maxDate = new Date;
    @ViewChild('f') siteForm: NgForm;
    selectedCustomer: '';
    

    constructor(private customerSiteService: CustomerAndSiteService,
        private tdLoadingService: TdLoadingService,
        private upgradeSchedulerService: UpgradeSchedulerService
    ) {
        this.minDate = new Date();
        this.maxDate.setMonth(this.maxDate.getMonth() + 2);
        this.platforms = platforms
    
    }

    ngOnInit(){
        this.selectedCustomer ='';
        this.selectedPlatform = '';
        
    }

  

    getCustomersForPlatformSelected(platform){
        this.customerListByPlatform =[];
        this.sites = [];
        this.selectedCustomer = '';
        let allCustomersList = this.upgradeSchedulerService.getCustomerAndSiteInfo();
        this.customerListByPlatform = this.customerSiteService.getCustomersByPlatform(allCustomersList,platform);
           
    }

    scheduleRadioGroupChecked(site) {
        site.selectedForSchedule = true;
    }

    updateSiteForScheduleBasedOnUserInput(changedSites) {
        this.sites.forEach((site => {
            if (changedSites[site.name] && !changedSites[site.name].touched) {
                //let index =  this.sites.indexOf(site);
                site.selectedForSchedule = false;
            }
        }))
    }

    getSitesForCustomerSelected(selectedCustomer) {
        let tempObj = {
             customer:selectedCustomer,
             platform:this.selectedPlatform
        }
        this.emitSelectedSites.emit(JSON.stringify(tempObj));
    }

    siteScheduleCheckboxChanged(site) {
        site.scheduleType = site.selectedForSchedule ? 'SCHEDULE' : '';

        if (!site.selectedForSchedule && !this.isReschedule) {
            site.scheduleType = undefined;
            site.date = undefined;
            site.time = undefined;
        }
    }

    resetCustomer() {
        this.selectedCustomer = '';
    }

    continue() {
        if (this.siteForm.touched) {
            this.siteForm.controls
            let obj = {};

            for (let key in this.siteForm.controls) {
                let tmpKey = key.substring(0, key.length - 1);
                if (tmpKey in obj) {
                    if (this.siteForm.controls[key].touched) {
                        obj[tmpKey].touched = true;
                    }
                } else {

                    if (this.siteForm.controls[key].touched) {
                        obj[tmpKey] = { 'touched': true };
                    } else {
                        obj[tmpKey] = { 'touched': false };
                    }

                }
            }
            this.updateSiteForScheduleBasedOnUserInput(obj);
            return true;

        } else {
            return false;

        }
    }
}
