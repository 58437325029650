import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable, OnInit, EventEmitter } from "@angular/core";
import * as moment from "moment";
import { Observable } from "rxjs/Observable";
import { of } from "rxjs/observable/of";
import { flatMap, map, max, shareReplay } from "rxjs/operators";
import { FetchAccessTokenService } from "./fetch-access-token.service";
import { AppConfig } from "../app.config";
import { forkJoin } from "rxjs";

//const URL = "https://api.dev.upaas.teradatacloud.io/upg-mgr/v1/upgrade-schedules";
//const RELEAE_MANAGER_upgradeupgradeupgradeUrl = "https://api.dev.upaas.teradatacloud.io/release-mgr/v1/releases";

export interface Release {
  createDt?: Date;
  createdDate: string;
  releaseDate: string;
  releaseID: string;
  components;
  downTime: number;
  regions: string[];
  platform: string;
  whatsNewInRelease: string;
}

@Injectable()
export class UpgradeSchedulerService implements OnInit {
  private latestRelease: Observable<any>;
  public getSitesevent = new EventEmitter();
  public completedEvent = new EventEmitter();
  public emitUpgradeEvent = new EventEmitter();
  customerAndSiteInfo;
  allReleases;
  releaseNameAndId = {};

  upgradeUrl = this.apiConfig.getConfig("upgradeUrl");
  releaseMgrUrl = this.apiConfig.getConfig("releaseManagerUrl");
  getSiteDetailsInfoUrl = this.apiConfig.getConfig("getDetailedSiteInfo");
  allSchedules = [];

  constructor(
    private httpClient: HttpClient,
    private fetchAccessToken: FetchAccessTokenService,
    private apiConfig: AppConfig
  ) {
    //update access token functionality once the sys ops role is removed
  }

  ngOnInit() {}

  public addSchedule(schedule): Observable<any> {
    //update access token functionality once the sys ops role is removed
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.fetchAccessToken.getAccessToken()
    });
    return this.httpClient.post(this.upgradeUrl, schedule, {
      headers: headers
    });
  }

  updateSchedule(schedules): any {
    const payload = {
      cloudId: schedules.cloudId,
      releaseId: schedules.releaseId,
      siteId: schedules.siteId,
      scheduledOn: schedules.scheduledOn
    };
    // schedules.forEach((schedule) => {
    //   if (schedule.scheduleType === "NOW") {
    //     payload.sitesSchedule[schedule.name] = {
    //       type: schedule.scheduleType
    //     };
    //   } else {
    //     payload.sitesSchedule[schedule.name] = {
    //       type: "LATER",
    //       scheduledOn: moment(schedule.date).add(parseInt(schedule.time.split(":")[0], 10) * 60
    //         + parseInt(schedule.time.split(":")[1], 10), "m").toDate()
    //     };
    //   }
    // });
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.fetchAccessToken.getAccessToken()
    });
    return this.httpClient.put(
      this.upgradeUrl + "/" + schedules.scheduleId,
      payload,
      { headers: headers }
    );
  }

  public setCustomerAndSiteInfo(customerAndSiteInfo) {
    this.customerAndSiteInfo = customerAndSiteInfo;
  }

  public getCustomerAndSiteInfo() {
    return this.customerAndSiteInfo;
  }

  public setAllReleases(releases) {
    this.allReleases = releases;
  }

  public getAllReleases() {
    return this.allReleases;
  }

  public getReleases(): Observable<any> {
    return this.httpClient.get(this.releaseMgrUrl);
  }

  //sort the schedules as per date

  sortSchedulesAsPerScheduledOnDate(schedules) {
    schedules.sort(function(a, b) {
      a = new Date(a.scheduledOn);
      b = new Date(b.scheduledOn);
      return a > b ? -1 : a < b ? 1 : 0;
    });
    return schedules;
  }

  //get customer infor for a site
  getSiteAndCustomerInfo(siteId) {
    let tempObj = {
      customerName: "",
      siteName: "",
      purpose: "",
      customerId: ""
    };
    return this.httpClient
      .get(this.getSiteDetailsInfoUrl + siteId, {
        headers: {
          Authorization: this.fetchAccessToken.getAccessToken()
        },
        withCredentials: false
      })
      .pipe(
        map((siteAndCustInfo: any) => {
          tempObj.customerId = siteAndCustInfo.result.customerId;
          tempObj.customerName = siteAndCustInfo.result.customerName;
          tempObj.purpose = siteAndCustInfo.result.purpose;
          tempObj.siteName = siteAndCustInfo.result.siteName;
          return tempObj;
        })
      );
  }

  public setReleaseNameAndId() {
    this.allReleases.forEach(release => {
      this.releaseNameAndId[release.releaseID] = release.releaseName;
    });
  }

  public getSiteAndCustomerInfoForAllSchedules(schedules) {
    let ob = [];
    let sortedSchedules = this.sortSchedulesAsPerScheduledOnDate(schedules);
    sortedSchedules.forEach(schedule => {
      ob.push(this.getSiteAndCustomerInfo(schedule.siteId));
    });

    return forkJoin(ob).pipe(
      map((data: any[]) => {
        schedules.forEach(schedule => {
          let tmpObj = {
            customer: {},
            scheduledOn: "",
            releaseName: "",
            scheduleId: ""
          };
          tmpObj.releaseName = this.releaseNameAndId[schedule.releaseId];
          tmpObj.scheduleId = schedule.id;
          tmpObj.scheduledOn = moment(schedule.scheduledOn)
            .local()
            .format("YYYY-MM-DD HH:mm");
          data.forEach(siteAndCustInfo => {
            if (siteAndCustInfo.siteName == schedule.siteId) {
              tmpObj.customer = siteAndCustInfo;
            }
          });
          this.allSchedules.push(tmpObj);
        });
        return this.allSchedules;
      })
    );
  }

  //fetch all the schedules for scheduled-upgarde tab
  public getAllSchedules(): Observable<any> {
    this.allSchedules = [];
    if (!this.allReleases) {
      this.getReleases().subscribe(releases => {
        this.allReleases = releases;
        this.setReleaseNameAndId();
      });
    } else {
      this.setReleaseNameAndId();
    }

    return this.httpClient.get(this.upgradeUrl, {
      headers: {
        Authorization: this.fetchAccessToken.getAccessToken()
      }
    });
  }

  //TODO complete the delete functionality
  public deleteSchedule(scheduleId): Observable<any> {
    return this.httpClient.delete(this.upgradeUrl + "/" + scheduleId, {
      headers: {
        Authorization: this.fetchAccessToken.getAccessToken()
      }
    });
  }

  public getSchedule(cloudId, releaseID): Observable<any> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.fetchAccessToken.getAccessToken()
    });
    return this.httpClient
      .get(this.upgradeUrl, {
        params: {
          cloudId: cloudId,
          releaseId: releaseID
        },
        headers: headers
      })
      .pipe(
        map((response: any) => {
          let tempResponse = response.reduce((acc, currVal) => {
            let tmpObj = {
              siteId: "",
              scheduleType: "",
              scheduledOn: "",
              id: "",
              cloudId: ""
            };
            tmpObj.siteId = currVal.siteId;
            tmpObj.scheduleType = "LATER";
            tmpObj.scheduledOn = currVal.scheduledOn;
            tmpObj.id = currVal.id;
            tmpObj.cloudId = currVal.cloudId;
            acc.push(tmpObj);
            return acc;
          }, []);
          return tempResponse;
        })
      );
  }

  public startSchedule(schedule) {
    if (schedule.isReschedule) {
      return this.updateSchedule(schedule);
    } else {
      delete schedule.isReschedule;
      return this.addSchedule(schedule);
    }
  }
}
