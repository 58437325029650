import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  MatButtonModule, MatIconModule, MatFormFieldModule,
  MatInputModule, MatSelectModule,
    MatSidenavModule, MatDividerModule,
    MatPaginatorModule, MatTableModule,
     MatCardModule,
      MatListModule,
       MatTooltipModule,
       MatToolbarModule,
       MatDatepickerModule,
       MatNativeDateModule,
       MatAutocompleteModule,
       MatSortModule,
       MatCheckboxModule,
       MatRadioModule,
       MatSnackBarModule,
       MatMenuModule,
       MatExpansionModule,
       MatProgressBarModule
} from '@angular/material';

import { CovalentLayoutModule, CovalentCommonModule, CovalentSearchModule, CovalentLoadingModule, CovalentStepsModule, CovalentMessageModule, CovalentDialogsModule } from '@covalent/core';

const ANGULAR_MATERIAL_MODULES = [
    MatIconModule,
    MatPaginatorModule,
    MatTableModule,
    MatCardModule,
    MatDividerModule,
    MatListModule,
    MatTooltipModule,
    MatToolbarModule,
    MatSidenavModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    MatAutocompleteModule,
    MatSortModule,
    MatButtonModule,
    MatSelectModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSnackBarModule,
    MatSelectModule,
    MatMenuModule,
    MatExpansionModule,
    MatProgressBarModule
];

const COVALENT_MODULES = [
    CovalentLayoutModule,
    CovalentCommonModule,
    CovalentSearchModule,
    CovalentLoadingModule,
    CovalentStepsModule,
    CovalentMessageModule,
    CovalentDialogsModule
];

@NgModule({
  imports: [
    CommonModule,
    ANGULAR_MATERIAL_MODULES,
    COVALENT_MODULES
  ],
  exports: [ANGULAR_MATERIAL_MODULES, COVALENT_MODULES]
})
export class SharedModule { }
