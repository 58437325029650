import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../app.config';



@Injectable({
  providedIn: 'root'
})
export class ReleaseService {

  constructor(private httpClient: HttpClient, 
    private appConfig: AppConfig) { }

  getReleaseById(releaseId) {
    let url = this.appConfig.getConfig('releaseManagerUrl') + '/' + releaseId
    return this.httpClient.get(url);
  }

}
