import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from './shared/modules/shared.material.module';
import { ScheduleUpgradeComponent } from './components/schedule-upgrade/schedule-upgrade.component';
import { ScheduleSitesComponent } from './components/schedule-sites/schedule-sites.component';
import { ConfirmUpgradeComponent } from './components/confirm-upgrade/confirm-upgrade.component';
import { ReleaseDetailComponent } from './components/release-detail/release-detail.component';
import { UpgradeSchedulerService } from './services/upgrade-scheduler.service';
import { UPaaSRouter } from './app.route';
import { APP_INITIALIZER } from '@angular/core';
import { AppConfig } from './app.config';
import { LoginComponent } from './components/login/login.component';
import { FetchAccessTokenService } from './services/fetch-access-token.service';
import { CustomerAndSiteService } from './services/customer-and-site.service';
import { SuccessFailureComponent } from './components/success-failure/success-failure.component';
import { JobDetailsDisplayComponent } from './components/job-details-display/job-details-display.component';
import { GetJobDetailsService } from './services/job-details.service';
import { UpgradeComponent } from './components/upgrade/upgrade.component';
import { UpgradeService } from './services/upgrade.service';
import { AuthGuardService } from './services/auth-guard.service';
import { ScheduledUpgradesHistoryComponent } from './components/scheduled-upgrades-history/scheduled-upgrades-history.component';
import { FailureAnalysisComponent } from './components/failure-analysis/failure-analysis.component';
import { FailureAnalysisMainComponent } from './components/failure-analysis-main/failure-analysis-main.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { AddFailureReasonDialogComponent } from './shared/dialogs/add-failure-reason-dialog/add-failure-reason-dialog.component';
import { JobDetailsTableComponent } from './components/job-details-table/job-details-table.component';
import { NoDataFoundComponent } from './components/no-data-found/no-data-found.component';


@NgModule({
  declarations: [
    AppComponent,
    ScheduleUpgradeComponent,
    ScheduleSitesComponent,
    ConfirmUpgradeComponent,
    ReleaseDetailComponent,
    LoginComponent,
    SuccessFailureComponent,
    JobDetailsDisplayComponent,
    UpgradeComponent,
    ScheduledUpgradesHistoryComponent,
    FailureAnalysisComponent,
    FailureAnalysisMainComponent,
    SearchBarComponent,
    AddFailureReasonDialogComponent,
    JobDetailsTableComponent,
    NoDataFoundComponent
  ],
  entryComponents: [
    SuccessFailureComponent,
    AddFailureReasonDialogComponent
],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxChartsModule,
    ReactiveFormsModule,
    SharedModule,
    UPaaSRouter
  ],
  providers: [UpgradeSchedulerService ,FetchAccessTokenService,CustomerAndSiteService,AuthGuardService,GetJobDetailsService,UpgradeService,AppConfig,{
    provide: APP_INITIALIZER, useFactory: (config: AppConfig) => () => config.load(), deps: [AppConfig],
    multi: true
}],
  bootstrap: [AppComponent]
})
export class AppModule { }
