<div class="container">
  <form>
    <div layout="row" style="margin-bottom: 8px;" *ngIf="sites && sites.length">
      <label>Schedule/Reschedule upgrade for the systems below.</label>
    </div>
    <div>
      <div layout="column" layout-align="start start">
        <mat-form-field class="selection-dropdown">
          <mat-select placeholder="Select Platform" name="selectedPlatform" [(ngModel)]="selectedPlatform"
            (selectionChange)="getCustomersForPlatformSelected(selectedPlatform)" >
            <mat-option *ngFor="let platform of platforms" [value]="platform.value">
              {{platform.label}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="selectedPlatform" class="selection-dropdown">
          <mat-select placeholder="Select a Customer" name="selectedCustomer" [(ngModel)]="selectedCustomer"
            (selectionChange)="getSitesForCustomerSelected(selectedCustomer)"> 
            <mat-option *ngFor="let customer of customerListByPlatform" [value]="customer">
              {{customer.customerName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <form #f="ngForm" *ngIf="sites.length" style="margin-top:10px;">
          <div *ngFor="let site of sites">
            <div layout="row" layout-align="start center">
              <div layout="column">
                <mat-checkbox [ngModelOptions]="{standalone: true}" [(ngModel)]="site.selectedForSchedule"
                  (change)="siteScheduleCheckboxChanged(site)"></mat-checkbox>
              </div>
              <div layout="column" *ngIf="site.platform != 'azure'">
                &nbsp; {{site.name}}&nbsp;( {{site.purpose}} )
              </div>
              <div layout="column" *ngIf="site.platform == 'azure'">
                &nbsp; {{site.supportSiteId}}&nbsp;( {{site.purpose}} )
              </div>
            </div>
            <div class="site">
              <br>
              <ng-template [ngIf]="site.scheduleType === 'SCHEDULE'">
                <label>Please select start date & time for the maintenance window.</label>
                <br>
                <div layout="row" layout-align="start center">
                  <div layout="column" flex="60">
                    <mat-form-field>
                      <input matInput [min]="minDate" [max]="maxDate" [name]="site.name + '1'" [(ngModel)]="site.date"
                        [matDatepicker]="picker" placeholder="Start Date">
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                  </div>
                  <div layout="column" flex="40" class="time">
                    <mat-form-field>
                      <input [name]="site.name + '2'" [(ngModel)]="site.time" matInput type="time"
                        placeholder="Start Time">
                    </mat-form-field>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </form>
      </div>
    </div>
  </form>
</div>
