import { Component, OnInit, Input, Output } from '@angular/core';
import { UpgradeSchedulerService } from 'src/app/services/upgrade-scheduler.service';
import { CustomerAndSiteService } from 'src/app/services/customer-and-site.service';
import { finalize } from 'rxjs/operators';
import { TdLoadingService, TdDialogService } from '@covalent/core';
import { MatSnackBar } from '@angular/material';
import { UpgradeService } from 'src/app/services/upgrade.service';
import { NgForm } from '@angular/forms';
import { SuccessFailureComponent } from '../success-failure/success-failure.component';
import { Router } from '@angular/router';
import { platforms } from '../../shared/models/platform.data';
import { FetchAccessTokenService } from 'src/app/services/fetch-access-token.service';
import { allProducts } from '../../shared/models/product.data';

@Component({
  selector: 'app-upgrade',
  templateUrl: './upgrade.component.html',
  styleUrls: ['./upgrade.component.css']
})
export class UpgradeComponent implements OnInit {

  customerAndSiteList;
  customerList = [];
  sites = [];
  showSites = false;
  showProduct = false;
  allReleases = [];
  showCheckBoxes = false;
  installProduct = false;
  showToVersion = false;
  installOS = false;
  showCustomer = false;
  allProducts = allProducts;
  products = [];
  platforms = [];
  dialogRef;
  successOrFailureMessage;
  customerListByPlatform;
  selectedPlatform;

  stages = [
    {
      "label": "pre upgrade",
      "value": "pre-check,backup,download"
    },
    {
      "label": "upgrade ",
      "value": "all"
    }
  ]


  constructor(private upgradeSchedulerService: UpgradeSchedulerService,
    private customerSiteService: CustomerAndSiteService,
    private tdLoadingService: TdLoadingService, private tdDialogService: TdDialogService,
    private snackBar: MatSnackBar, private upgradeService: UpgradeService,
    private fetchAccessToken: FetchAccessTokenService,
    private router: Router) { 
      this.platforms = platforms;
    }

  getCustomerList(data) {
    for (let key in data) {
      this.customerList.push(data[key].customer);
    }
    this.customerList.sort(function (a, b) {
      var textA = a.customerName.toUpperCase();
      var textB = b.customerName.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });
  }

  getSitesForCustomerSelected(customer) {
    this.sites = this.customerAndSiteList[customer.customerId].sites.filter((s)=>{
        return s.platform == this.selectedPlatform;
    });
    this.showSites = true;
  }

  init() {
    if (this.upgradeSchedulerService.getCustomerAndSiteInfo()) {
      this.customerAndSiteList = this.upgradeSchedulerService.getCustomerAndSiteInfo();
      this.getCustomerList(this.upgradeSchedulerService.getCustomerAndSiteInfo());
    } else {
      this.tdLoadingService.register('upgradeLoad');
      this.customerSiteService.getAllSites().pipe(finalize(() => {
        this.tdLoadingService.resolve('upgradeLoad');
      })).subscribe((data) => {
        this.customerAndSiteList = data;
        this.getCustomerList(data);

      })
    }
    if (this.upgradeSchedulerService.getAllReleases()) {
      this.allReleases = this.upgradeSchedulerService.getAllReleases();
    } else {
      this.upgradeSchedulerService.getReleases().pipe(finalize(() => {

      })).subscribe((releases) => {
        this.allReleases = releases;
        //this.getAllCustomers();
      }, (err) => {
        this.snackBar.open("Could not fetch releases!Login and try again", 'Ok');
      })
    }
  }

  ngOnInit() {
    if(localStorage.getItem('isLoggedIn') === 'false'){
      this.router.navigate(['/'])
    }else if(!this.fetchAccessToken.loginSubscription){
      this.fetchAccessToken.refreshToken();           
      this.init();
    }else{
      this.init();
    }    
  }

  upgrade(f: NgForm) {
    this.tdLoadingService.register('upgradeLoad');
    let payload = {
      product: f.value.selectedProduct.value,
      toVersion: f.value.selectedProduct.toVersion,
      releaseId: f.value.selectedRelease.releaseID,
      stage: f.value.selectedStage,
      installOS: this.installOS,
      installProduct: this.installProduct,
    };
    let siteNameOrId = f.value.selectedsite.platform == 'aws'? f.value.selectedsite.name: f.value.selectedsite.supportSiteId;
    this.upgradeService.upgrade(siteNameOrId, payload).pipe(finalize(() => {
      this.tdLoadingService.resolve('upgradeLoad');
      this.dialogRef = this.tdDialogService.open(SuccessFailureComponent, {
        data: this.successOrFailureMessage,
        width: '500px'
      });
    })).subscribe((response: any) => {
      this.successOrFailureMessage = {
        'isSuccess': true,
        'text': 'Upgrade started and job id is: ' + response.jobId
      }


    },
      (error) => {
       this.successOrFailureMessage = {
          'isSuccess': false,
          'text': error.error.message
        }
      });
  }

  getCustomersForPlatformSelected(platform) {
    this.showCustomer = false;
    this.customerListByPlatform = [];
    this.sites = [];
    let allCustomersList = this.upgradeSchedulerService.getCustomerAndSiteInfo()
    this.customerListByPlatform = this.customerSiteService.getCustomersByPlatform(allCustomersList, platform);
    this.showCustomer = true;
  }

  fetchProductFromSelectedRelease(selectedRelease, product, f: NgForm) {
    this.products = [];
    selectedRelease.components.forEach(cmp => {
      let tmpObj = { label: '', value: '', toVersion: '' };
      tmpObj.label = this.allProducts[cmp.name];
      tmpObj.value = cmp.name;
      tmpObj.toVersion = cmp.version;
      this.products.push(tmpObj);

    });
    this.showProduct = true;
  }

}
