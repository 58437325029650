import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material';
import { CustomerAndSiteService } from './services/customer-and-site.service';
import { Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { UpgradeSchedulerService } from './services/upgrade-scheduler.service';
import { FetchAccessTokenService } from './services/fetch-access-token.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnDestroy {
  //showTabs = false;
  intervalOb;
  subscription: Subscription;
  constructor(private _iconRegistry: MatIconRegistry,
    private _domSanitizer: DomSanitizer,
    private fetchAccessToken: FetchAccessTokenService
  ) {

    // this is just mat icon registry to show teradata logo in header
    this._iconRegistry.addSvgIconInNamespace('assets', 'teradata',
      this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/teradata.svg'));
  }

  @HostListener("window:beforeunload ", ["$event"])
  public beforeunloadHandler($event) {
    
      localStorage.setItem('isLoggedIn', 'false');
  }

  ngOnDestroy() {
    this.fetchAccessToken.loginSubscription.unsubscribe();
  }

}
