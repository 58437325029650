import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AppConfig } from "../app.config";
import { FetchAccessTokenService } from "./fetch-access-token.service";
import { Observable, Subject, forkJoin } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class GetJobDetailsService {
  jobDetailsRecievedEvent = new Subject();
  allJobDetails = [];

  constructor(
    private httpClient: HttpClient,
    private appConfig: AppConfig,
    private getAccessToken: FetchAccessTokenService
  ) {}

  public getJobDetailsById(jobId, isDetailsChecked) {
    let jobDetailsUrl = this.appConfig.getConfig("jobDetails") + jobId;
    let accessToken = this.getAccessToken.getAccessToken();
    let headers = new HttpHeaders({ Authorization: accessToken });

    return this.httpClient.get(jobDetailsUrl, {
      headers: headers,
      withCredentials: false,
      params: { details: isDetailsChecked }
    });
  }

  public getJobsByReleaseAndSite(siteName, releaseId) {
    let headers = new HttpHeaders({
      Authorization: this.getAccessToken.getAccessToken()
    });
    let preUpgradepayloadReq = this.httpClient.get(
      this.appConfig.getConfig("jobDetails"),
      {
        headers: headers,
        params: {
          siteName: siteName,
          release_id: releaseId,
          stage: "pre-check,backup,download"
        },
        withCredentials: false
      }
    );

    let upgradepayloadReq = this.httpClient.get(
      this.appConfig.getConfig("jobDetails"),
      {
        headers: headers,
        params: {
          siteName: siteName,
          release_id: releaseId
        },
        withCredentials: false
      }
    );

    return forkJoin([preUpgradepayloadReq, upgradepayloadReq]).pipe(
      map((response: any) => {
        return response[0].concat(response[1]);
      })
    );
  }

  setAllJobDetails(jobDetails) {
    this.allJobDetails = jobDetails;
  }

  getAllJobDetails() {
    return this.allJobDetails;
  }

  public getAllJobDetailsFromApi(startDate: string, endDate: string) {
    const body = {
      start_time: startDate,
      end_time: endDate,
      status: "FAILED"
    };
    let jobDetailsUrl = this.appConfig.getConfig("jobDetails");
    //change below line for prod
    return this.httpClient
      .get(jobDetailsUrl, {
        headers: { Authorization: this.getAccessToken.getAccessToken() },
        params: body
      })
      .pipe(
        map((res: any) => {
          return res.filter(r => {
            return !r.stage.includes("pre-check");
          });
        })
      );
  }
}
