import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Inject } from '@angular/core';

import { TdLoadingService } from '@covalent/core';
import { FailureAnalysisService } from 'src/app/services/failure-analysis.service';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';
import { ReleaseService } from 'src/app/services/release.service';


@Component({
  selector: 'app-reschedule-dialog',
  templateUrl: './add-failure-reason-dialog.component.html',
  styleUrls: ['./add-failure-reason-dialog.component.css']
})
export class AddFailureReasonDialogComponent implements OnInit {
  isSuccess = false;
  showMessage = false;
  failureReason: '' = '';
  failureCategory: '' = '';
  message = '';
  releaseLabel = '';
  disableActionOnSubmit = false;
  failures = [{
    value: 'UPGRADE_TOOLS_ERROR',
    label: 'Software Upgrade Issue'
  },
  {
    value: 'USER_ERROR',
    label: 'User Error'
  }, {
    value: 'ENVIRONMENT_ERROR',
    label: 'Environment Issue'
  }, {
    value: 'SERVICE_ERROR',
    label: 'Service Issues'
  }]

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddFailureReasonDialogComponent>,
    private loadingService: TdLoadingService,
    private failureService: FailureAnalysisService,
    private releaseService: ReleaseService) {


  }

  ngOnInit() {
    this.loadingService.register('failureReasonDialogLoader');
    this.failureService.getFailureByJobId(this.data.id).pipe(finalize(() => {
      this.loadingService.resolve('failureReasonDialogLoader');
    })).subscribe((response: any) => {
      this.failureCategory = response.category;
      this.failureReason = response.failureReason;
    }, () => {


    })
  }

  closeDialog(message) {
    if (this.isSuccess && message) {
      this.dialogRef.close(message)
    } else {
      this.dialogRef.close();
    }
  }
  saveFailureInfo() {
    let payload = {
      "siteName": this.data.siteName,
      "product": this.data.product,
      "toVersion": this.data.to_version,
      "date": moment(this.data.start_time).format('YYYY-MM-DD'),
      "stage": this.data.stage,
      "failureReason": this.failureReason,
      "category": this.failureCategory,
      "releaseId": this.data.release_id,
      "customerName": this.data.customer_name,
      "purpose": this.data.purpose,
      "platform": this.data.platform,
      "releaseLabel": this.releaseLabel
    }

    this.failureService.saveFailureByJobId(this.data.id, payload).pipe(finalize(() => {
      this.loadingService.resolve('failureReasonDialogLoader');
    })).subscribe((response) => {
      this.message = 'Successfully updated the failure information for job id: ';
      this.showMessage = true;
      this.isSuccess = true;
    }, (error) => {
      this.message = 'There was some error in updating failure information for job id: ';
      this.showApiFailure();
    });
  }

   showApiFailure(){
    this.showMessage = true;
    this.isSuccess = false;
    this.loadingService.resolve('failureReasonDialogLoader');
   }


   submit() {
    //this.loadingService.register('failureReasonDialogLoader');
    this.loadingService.register('failureReasonDialogLoader');
    this.disableActionOnSubmit = true;
    this.showMessage = false;
   
    //fetch release by id to get label or version of the release to send it in post api payload  
    this.releaseService.getReleaseById(this.data.release_id).subscribe((release: any) => {
      if(release &&  release.releaseLabel){
        this.releaseLabel = release.releaseLabel;
        this.saveFailureInfo();
      }else{
         this.message = 'There was some error in fetching release version for updating failure information';   
         this.showApiFailure();
      }
 
    }, () => {
      this.message = 'There was some error in fetching release version for updating failure information';
      this.showApiFailure();
    })
  }
}
