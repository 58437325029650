import { Component, OnInit, Input, ViewChild, OnChanges } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';

@Component({
  selector: 'app-job-details-table',
  templateUrl: './job-details-table.component.html',
  styleUrls: ['./job-details-table.component.css']
})
export class JobDetailsTableComponent implements OnInit,OnChanges {
  @Input() inputDataSource;
  private paginator: MatPaginator;
  private sort: MatSort;
  dataSource;
  showTable = false;
  displayedColumnsForPhaseDetails: string[] = ['phaseName', 'status', 'message'];

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  constructor() {
    this.dataSource = new MatTableDataSource(this.inputDataSource);
   }

  ngOnInit() {
    this.dataSource.data = this.inputDataSource;    
  }

  ngOnChanges(){
    this.dataSource.data = this.inputDataSource;
  }
}
