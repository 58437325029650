<mat-card>
  <mat-card-header>
    <mat-card-title>
      <div layout="row" layout-align="center center">
        Job Details
      </div>
    </mat-card-title>
  </mat-card-header>
  <mat-divider></mat-divider>

  <div *tdLoading="'failureLoader'; mode:'indeterminate'; type:'circle'; strategy:'overlay'; color:'primary'">

    <mat-card-content *ngIf="dataFound">
      <div>

        <table mat-table [dataSource]="dataSource" matSort>
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Job Id </th>
            <td mat-cell *matCellDef="let element" class="uppercase"> {{element.id}} </td>
          </ng-container>

          <ng-container matColumnDef="product">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Product </th>
            <td mat-cell *matCellDef="let element"> {{element.product}} </td>
          </ng-container>

          <ng-container matColumnDef="siteName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Site Name</th>
            <td mat-cell *matCellDef="let element"> {{element.siteName}} </td>
          </ng-container>

          <ng-container matColumnDef="customer_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Customer Name</th>
            <td mat-cell *matCellDef="let element"> {{element.customer_name}} </td>
          </ng-container>


          <ng-container matColumnDef="purpose">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Purpose</th>
            <td mat-cell *matCellDef="let element"> {{element.purpose}} </td>
          </ng-container>

          <ng-container matColumnDef="save">
            <th mat-header-cell *matHeaderCellDef class="extra-pad-left">Edit</th>
            <td mat-cell *matCellDef="let element;let i=index">
              <a class="cursor-pointer" mat-button color="accent" (click)="saveFailureReason(element)">
                <mat-icon>edit</mat-icon>
              </a>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
      <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
    </mat-card-content>

    <div layout="row" layout-align="center center" *ngIf="!dataFound"
      class="center-aligned position-relative extra-pad ">
      <div layout="column" layout-align="center center">
        <mat-icon class="tc-grey-500 large-font">info</mat-icon>
        <h3 class="tc-grey-500 ">No info found</h3>
        <span class="tc-grey-500 bold">No job details available for the selected date range.</span>

      </div>
    </div>
  </div>

</mat-card>
