<td-layout-card-over cardWidth="75" class="imc box-form">
  <mat-card-header>
     <mat-card-title>
       Upgrade history
     </mat-card-title>
   </mat-card-header>
   <mat-divider></mat-divider>
 
   <mat-card-content *tdLoading="'scheduleDetailsMainLoader'; mode:'indeterminate'; type:'circle'; strategy:'overlay'; color:'primary'" >
     <div  *tdLoading="'deleteScheduleloader'; mode:'indeterminate'; type:'circle'; strategy:'overlay'; color:'primary'">
 
     <table mat-table [dataSource]="dataSource" matSort >
 
       <!-- Position Column -->
       <ng-container matColumnDef="customerName">
         <th mat-header-cell *matHeaderCellDef mat-sort-header> Customer </th>
         <td mat-cell *matCellDef="let element" class="width-40" style="text-transform: uppercase"> {{element.customer.customerName}} </td>
       </ng-container>
 
       <!-- Name Column -->
       <ng-container matColumnDef="siteName">
         <th mat-header-cell *matHeaderCellDef mat-sort-header> Site </th>
         <td mat-cell *matCellDef="let element"> {{element.customer.siteName}} </td>
       </ng-container>
 
       <!-- Weight Column -->
       <ng-container matColumnDef="purpose">
         <th mat-header-cell *matHeaderCellDef mat-sort-header> Purpose </th>
         <td mat-cell *matCellDef="let element"> {{element.customer.purpose}} </td>
       </ng-container>
 
 
       <ng-container matColumnDef="releaseName">
         <th mat-header-cell *matHeaderCellDef mat-sort-header  class="width-40"> Release Name</th>
         <td mat-cell *matCellDef="let element" class="width-40"> {{element.releaseName}} </td>
       </ng-container>
 
 
       <ng-container matColumnDef="scheduledOn">
         <th mat-header-cell *matHeaderCellDef mat-sort-header>Scheduled On</th>
         <td mat-cell *matCellDef="let element"> {{element.scheduledOn}} </td>
       </ng-container>
 
       <ng-container matColumnDef="actions">
         <th mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
         <td mat-cell *matCellDef="let element" class="width-10">
           <button mat-icon-button [matMenuTriggerFor]="menu">
             <mat-icon class="gray-color">more_verti</mat-icon>
           </button>
           <mat-menu #menu="matMenu">
             <button (click)="deleteSchedule(element)" mat-menu-item>
                 <mat-icon class="tc-blue-700">delete</mat-icon>
                 <span>Cancel</span>
             </button>
           </mat-menu>
         </td>
       </ng-container>
 
       <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
       <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
    
     
 
 
     <!-- <div layout="column" *ngIf="releases && releases.length === 0" layout-align="center center" class="tc-grey-500 mat-typography pad-lg">
               <mat-icon matListAvatar class="text-super push-bottom">chrome_reader_mode</mat-icon>
               <h2>No Releases</h2>
               <h3>You have not created any release</h3>
           </div> -->
 
   </mat-card-content>
 
 </td-layout-card-over>
 