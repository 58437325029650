import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfig } from "../app.config";
import { FetchAccessTokenService } from "./fetch-access-token.service";

@Injectable({
  providedIn: "root"
})
export class UpgradeService {
  constructor(
    private httpClient: HttpClient,
    private appConfig: AppConfig,
    private fetchAccessToken: FetchAccessTokenService
  ) {}

  upgrade(siteId, payload) {
    let url = this.appConfig.getConfig("directUpgradeUrl") + "/" + siteId;

    return this.httpClient.post(url, payload, {
      headers: { Authorization: this.fetchAccessToken.getAccessToken() },
      withCredentials: false
    });
  }
}
