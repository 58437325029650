<div>
    <label>Review & confirm the selected systems & scheduled timings for the upgrade.</label>
    <br>
    <br>
    <ng-container *ngFor="let site of sites">
        <div class="tile" *ngIf="site.scheduleType">
            <div layout="row" layout-align="start start">
                <div layout="column" flex="5">
                    <mat-icon>stop</mat-icon>
                </div>
                <div layout="column" flex="25">
                    <p class="head">Site</p>
                    <p class="content" *ngIf="site.platform != 'azure'">{{site.name}}</p>
                    <p class="content" *ngIf="site.platform == 'azure'">{{site.supportSiteId}}</p>
                        <br>( {{site.purpose}} )
                </div>
                <div layout="column" flex="40">
                    <p class="head">Software to be Updated</p>
                    <p class="content">
                        <li matLine class="dark" *ngFor="let component of release.components">{{allProducts[component.name] + ' ' + component.version}}</li>
                    </p>
                </div>
                <div layout="column" flex="25">
                    <ng-template [ngIf]="site.scheduleType === 'NOW'">
                        <p class="head">Scheduled</p>
                        <p class="content">
                            NOW
                        </p>
                    </ng-template>
                    <ng-template [ngIf]="site.scheduleType !== 'NOW'">
                        <p class="head">Scheduled on</p>
                        <p class="content">{{site.date | date :'longDate'}} {{site.time}}</p>
                    </ng-template>
                </div>
            </div>
            <br>
        </div>
        <br>
    </ng-container>
</div>