import { Component } from "@angular/core";
import { Release } from "../../services/upgrade-scheduler.service";
import { allProducts } from '../../shared/models/product.data';

@Component({
  selector: "mc-confirm-upgrade",
  templateUrl: "./confirm-upgrade.component.html",
  styleUrls: ["./confirm-upgrade.component.scss"]
})
export class ConfirmUpgradeComponent {
  sites = [];
  release: Release;
  allProducts;
  constructor() {
    this.allProducts = allProducts;
  }
}
