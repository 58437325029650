<td-layout-card-over cardWidth="75" class="imc box-form">
  <div *tdLoading="'upgradeLoad'; mode:'indeterminate'; type:'circle'; strategy:'overlay'; color:'primary'">
    <mat-card-title>
      <div layout="row" layout-align="start center">
        <span class="pad-left-sm mat-title push-bottom-none">Upgrade Product</span>
      </div>
    </mat-card-title>
    <mat-divider></mat-divider>
    <mat-card-content>
      <form name="form" (ngSubmit)="f.form.valid && onSubmit(f)" #f="ngForm" novalidate>
        <div layout="column" layout-align="center start">
          <mat-form-field>
            <mat-select placeholder="Select Platform" name="selectedPlatform" [(ngModel)]="selectedPlatform"
              (selectionChange)="getCustomersForPlatformSelected(selectedPlatform)">
              <mat-option *ngFor="let platform of platforms" [value]="platform.value">
                {{platform.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field *ngIf="showCustomer">
            <mat-select placeholder="Select a Customer" name="selectedCustomer" [(ngModel)]="selectedCustomer"
              (selectionChange)="getSitesForCustomerSelected(selectedCustomer)" required>
              <mat-option *ngFor="let customer of customerListByPlatform" [value]="customer">
                {{customer.customerName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field *ngIf="showSites">
            <mat-select placeholder="Select a site" name="selectedsite" [(ngModel)]="selectedSite"
              (selectionChange)="showRelease=true" required>
              <mat-option *ngFor="let site of sites" [value]="site">
               <span *ngIf="site.platform != 'azure'">{{site.name}}</span> 
               <span *ngIf="site.platform == 'azure'">{{site.supportSiteId}}</span> 
              </mat-option>
            </mat-select>
          </mat-form-field>


          <mat-form-field *ngIf="showRelease">
            <mat-select placeholder="Select a Release" name="selectedRelease" [(ngModel)]="selectedRelease"
              (selectionChange)="fetchProductFromSelectedRelease(selectedRelease,f)" required>
              <mat-option *ngFor="let release of allReleases" [value]="release">
                {{release.releaseName}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field *ngIf="showProduct">
            <mat-select placeholder="Select a product" name="selectedProduct" [(ngModel)]="selectedProduct"
              (selectionChange)="showToVersion=true;showStages=true" required>
              <mat-option *ngFor="let product of products" [value]="product">
                {{product.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field *ngIf="showToVersion">
            <input required name="toVersion" [(ngModel)]="selectedProduct.toVersion" [disabled]="true"
              #toVersion="ngModel" matInput placeholder="To Version" class="form-control" ngModel>
          </mat-form-field>
          <mat-form-field *ngIf="showStages">
            <mat-select placeholder="Select a Stage" name="selectedStage" [(ngModel)]="selectedStage"
              (selectionChange)="showCheckBoxes=true" required>
              <mat-option *ngFor="let stage of stages" [value]="stage.value">
                {{stage.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <section class="example-section" *ngIf="showCheckBoxes" required>
            <mat-checkbox class="example-margin" name="installProduct" [(ngModel)]="installProduct">Upgrade product
            </mat-checkbox>
            <mat-checkbox class="example-margin" name="installOS" [(ngModel)]="installOS">patch OS</mat-checkbox>
          </section>
        </div>
      </form>
    </mat-card-content>
    <mat-divider></mat-divider>
    <mat-card-actions>
      <div layout="row" flex="100" layout-align="start start">
        <div layout="row" layout-align="end">
          <button mat-button color="primary" class="text-upper" (click)="upgrade(f)"
            [disabled]="!f.valid && (installProduct==false && installOS==false)">Upgrade</button>
        </div>

      </div>
    </mat-card-actions>
  </div>
</td-layout-card-over>
