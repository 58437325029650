
<mat-table [dataSource]="dataSource" class="margin-table">
  <!-- Position Column -->
  <ng-container matColumnDef="phaseName">
    <mat-header-cell *matHeaderCellDef> Stage Name </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.phaseName}} </mat-cell>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="status">
    <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.status}} </mat-cell>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="message">
    <mat-header-cell *matHeaderCellDef> Message </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.message}} </mat-cell>
  </ng-container>


  <mat-header-row *matHeaderRowDef="displayedColumnsForPhaseDetails"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumnsForPhaseDetails;"></mat-row>

</mat-table>
<mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
