import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FetchAccessTokenService } from 'src/app/services/fetch-access-token.service';

@Component({
  selector: 'app-failure-analysis-main',
  templateUrl: './failure-analysis-main.component.html',
  styleUrls: ['./failure-analysis-main.component.css']
})
export class FailureAnalysisMainComponent implements OnInit {

  constructor(private router:Router,
    private fetchAccessToken:FetchAccessTokenService) { }

  ngOnInit() {
    if(localStorage.getItem('isLoggedIn') === 'false'){
      this.router.navigate(['/'])
    }else  if(!this.fetchAccessToken.loginSubscription){
      this.fetchAccessToken.refreshToken();             
    }
  }

}
