import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { GetJobDetailsService } from "../../services/job-details.service";
import { NgForm } from "@angular/forms";
import { TdLoadingService, TdDialogService } from "@covalent/core";
import { finalize } from "rxjs/operators";
import { MatSnackBar, MatExpansionPanel } from "@angular/material";
import { MatPaginator, MatTableDataSource } from "@angular/material";
import "rxjs/add/observable/interval";
import { Observable, Subscription } from "rxjs";
import "rxjs/add/operator/startWith";
import { Router } from "@angular/router";
import { untilComponentDestroyed } from "@w11k/ngx-componentdestroyed";
import { FetchAccessTokenService } from "src/app/services/fetch-access-token.service";
import { UpgradeSchedulerService } from "src/app/services/upgrade-scheduler.service";
import { allProducts } from "../../shared/models/product.data";

@Component({
  selector: "app-job-details-display",
  templateUrl: "./job-details-display.component.html",
  styleUrls: ["./job-details-display.component.css"]
})
export class JobDetailsDisplayComponent implements OnInit, OnDestroy {
  dataSourceForJobDetails = [];
  showPhaseTable = false;
  ob;
  obSubscription: Subscription;
  allJobs = [];
  selectedRelease = "";
  releases = [];
  loaderName;
  dataSourceForPhaseList = [];
  selectedJobIndex;
  showNoDataFoundMessage = false;
  showNoJobIdsMessage = false;
  products = allProducts;
  showLoader = [];
  isDetailsChecked = false;
  obArr = [];
  statusArr = {
    COMPLETED: "Completed",
    FAILED: "Failed",
    IN_PROGRESS: "In Progress",
    STARTED: "Started"
  };
  @ViewChild("searchForm") searchForm: NgForm;

  constructor(
    private jobDetails: GetJobDetailsService,
    private tdLoadingService: TdLoadingService,
    private snackBar: MatSnackBar,
    private router: Router,
    private tdDialogService: TdDialogService,
    private fetchAccessToken: FetchAccessTokenService,
    private upgradeSchedulerService: UpgradeSchedulerService
  ) {}

  ngOnInit() {
    if (localStorage.getItem("isLoggedIn") === "false") {
      this.router.navigate(["/"]);
    } else if (!this.fetchAccessToken.loginSubscription) {
      this.fetchAccessToken.refreshToken();
    }
    this.getAllReleases();
  }

  getAllReleases() {
    this.releases = this.upgradeSchedulerService.getAllReleases();
    if (!this.releases || !this.releases.length) {
      this.tdLoadingService.register("jobLoad");
      this.upgradeSchedulerService
        .getReleases()
        .pipe(
          finalize(() => {
            this.tdLoadingService.resolve("jobLoad");
          })
        )
        .subscribe(
          releases => {
            this.releases = releases;
            //this.getAllCustomers();
            this.upgradeSchedulerService.setAllReleases(this.releases);
          },
          err => {
            this.snackBar.open(
              "Could not fetch releases!Login and try again",
              "Ok"
            );
          }
        );
    }
  }

  parseDataForJobDetailsTable(data) {
    let tmpObj = {
      customerName: "",
      product: "",
      siteName: "",
      status: "",
      toVersion: ""
    };
    tmpObj.customerName = data.customer_name;
    tmpObj.product = data.product;
    tmpObj.siteName = data.siteName;
    tmpObj.status = data.status;
    tmpObj.toVersion = data.to_version;
    this.dataSourceForJobDetails.push(tmpObj);
  }

  parseDataForPhaseList(data) {
    let arr = [];
    this.showPhaseTable = false;
    if (data.phase_list) {
      data.phase_list.forEach(element => {
        element.actions.forEach(action => {
          let tmpObj = {
            phaseName: "",
            status: "",
            message: "",
            timestamp: ""
          };
          tmpObj.phaseName = element.phase_name;
          tmpObj.status = action.status;
          tmpObj.message = action.message;
          tmpObj.timestamp = action.timestamp;
          arr.push(tmpObj);
        });
      });
      arr.sort(function(a, b) {
        a = new Date(a.timestamp);
        b = new Date(b.timestamp);
        return a > b ? -1 : a < b ? 1 : 0;
      });
      this.tdLoadingService.resolve(this.loaderName);
      if (this.selectedJobIndex != undefined)
        this.dataSourceForPhaseList[this.selectedJobIndex] = arr;
      else this.dataSourceForPhaseList = arr;
    } else {
      this.showNoDataFoundMessage = true;
      this.tdLoadingService.resolve(this.loaderName);
    }
  }

  parseDataForTable(data) {
    if (this.selectedJobIndex != undefined)
      this.dataSourceForPhaseList[this.selectedJobIndex] = undefined;
    this.parseDataForPhaseList(data);
  }

  getJobsByReleaseAndSite(f: NgForm) {
    this.jobDetails
      .getJobsByReleaseAndSite(f.value.siteId, this.selectedRelease)
      .pipe(
        finalize(() => {
          this.tdLoadingService.resolve("jobLoad");
        })
      )
      .subscribe(
        (response: any) => {
          this.allJobs = response;
          if (!this.allJobs.length) {
            this.showNoJobIdsMessage = true;
          } else {
            this.showNoJobIdsMessage = false;
          }
        },
        () => {
          this.showNoJobIdsMessage = true;
          this.snackBar.open("Could not fetch jobs ! Please try again", "Ok");
        }
      );
  }

  onSubmit(f: NgForm) {
    this.tdLoadingService.register("jobLoad");
    this.resetVariables();
    if (f.value.siteId) {
      this.getJobsByReleaseAndSite(f);
    } else if (f.value.jobId) {
      this.getJobDetailsById({
        id: f.value.jobId,
        isDetailsChecked:
          this.isDetailsChecked == undefined ? false : this.isDetailsChecked
      });
    }
  }

  getJobDetailsFromApi(job) {
    this.jobDetails
      .getJobDetailsById(job.id, job.isDetailsChecked)
      .pipe(
        finalize(() => {
          if (this.selectedJobIndex != undefined) {
            this.showLoader[this.selectedJobIndex] = false;
          } else {
            this.tdLoadingService.resolveAll("jobLoad");
          }
        })
      )
      .subscribe(
        (data: any) => {
          if (this.selectedJobIndex == undefined && !data.phase_list) {
            this.showNoJobIdsMessage = true;
          } else {
            this.parseDataForTable(data);
          }
        },
        err => {
          this.snackBar.open(
            "Could not fetch details ! Please try again",
            "Ok"
          );
        }
      );
  }

  createPollingObservables(job) {
    return Observable.interval(30000)
      .startWith(0)
      .pipe(untilComponentDestroyed(this))
      .subscribe(int => {
        if (this.selectedJobIndex != undefined)
          this.showLoader[this.selectedJobIndex] = true;
        else {
          this.tdLoadingService.register("jobLoad");
        }
        this.getJobDetailsFromApi(job);
      });
  }

  getJobDetailsById(job, index?: number) {
    this.showNoDataFoundMessage = false;
    if (index != undefined) {
      //delete this.dataSourceForPhaseList[this.selectedJobIndex];
      this.selectedJobIndex = index;
      this.obArr[index] = this.createPollingObservables(job);
    } else {
      this.ob = this.createPollingObservables(job);
    }
  }

  resetForm() {
    this.searchForm.resetForm();
  }

  resetVariables() {
    this.unsubscribeAllObservables();
    this.selectedJobIndex = undefined;
    this.showNoJobIdsMessage = false;
    this.showNoDataFoundMessage = false;
    if (this.allJobs.length) this.allJobs.length = 0;
    if (this.dataSourceForPhaseList) this.dataSourceForPhaseList.length = 0;
  }

  refreshView() {
    this.resetVariables();
    this.resetForm();
  }

  expandPanel(
    matExpansionPanel: MatExpansionPanel,
    event: Event,
    job,
    index
  ): void {
    event.stopPropagation(); // Preventing event bubbling
    if (!this._isExpansionIndicator(event.target as HTMLElement)) {
      matExpansionPanel.toggle();
    } else if (matExpansionPanel.expanded) {
      this.uncheckOtherPanelDetails(index);
      this.unsubscribeAllObservables();
      this.getJobDetailsById(job, index);
    } else {
      job.isDetailsChecked = false;
      this.unsubscribeClosedPanelObs(index);
    }
  }

  private uncheckOtherPanelDetails(index) {
    this.allJobs.forEach((job, i) => {
      if (i != index) {
        job.isDetailsChecked = false;
      }
    });
  }

  private _isExpansionIndicator(target: HTMLElement): boolean {
    const expansionIndicatorClass = "mat-expansion-indicator";
    return (
      target.classList && target.classList.contains(expansionIndicatorClass)
    );
  }

  //unsubcribe the observable once panel closes
  unsubscribeClosedPanelObs(index) {
    this.obArr[index].unsubscribe();
  }

  unsubscribeAllObservables() {
    if (this.obArr.length) {
      //for site and release
      this.obArr.forEach(ob => {
        ob.unsubscribe();
      });
    }
    if (this.ob) {
      //for job id
      this.ob.unsubscribe();
    }
  }

  ngOnDestroy() {}
}
