import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { UpgradeSchedulerService } from 'src/app/services/upgrade-scheduler.service';


@Component({
  selector: 'app-success-failure',
  templateUrl: './success-failure.component.html',
  styleUrls: ['./success-failure.component.css']
})
export class SuccessFailureComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<SuccessFailureComponent> ,  
    @Inject(MAT_DIALOG_DATA) public message: any , private upgradeSchedulerService :UpgradeSchedulerService) { }

  ngOnInit() {
  }

  closeDialog(){
    this.upgradeSchedulerService.completedEvent.emit();
    this.dialogRef.close()
  }

}
