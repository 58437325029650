export const allProducts =
{
  'database': 'Database',
  'sqlengine': 'Vantage SQL Engine',
  'cmic': 'CMIC',
  'viewpoint': 'Viewpoint',
  'dsa': 'DSC',
  'querygrid': 'QueryGrid',
  'ecosystem': 'Ecosystem Manager',
  'query-service': 'Query Service',
  'load-server': 'Load Server',
  'data-mover': 'Data Mover',
  'os': 'OS',
  'barportlets-dsc-db':'Bar-Portlets DSA DB'
};