import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material";
import { MatSnackBar } from "@angular/material";
import { StepState, TdLoadingService, TdDialogService } from "@covalent/core";
import * as moment from "moment";
import { forkJoin } from "rxjs/observable/forkJoin";


import { ConfirmUpgradeComponent } from "../confirm-upgrade/confirm-upgrade.component";
import { ScheduleSitesComponent } from "../schedule-sites/schedule-sites.component";
import { UpgradeSchedulerService } from "../../services/upgrade-scheduler.service";
import { Router, ActivatedRoute } from "@angular/router";
import { AppConfig } from "src/app/app.config";
import { CustomerAndSiteService } from "src/app/services/customer-and-site.service";
import { finalize } from "rxjs/operators";
import { SuccessFailureComponent } from "../success-failure/success-failure.component";
import { ReleaseDetailComponent } from "../release-detail/release-detail.component";
import { FetchAccessTokenService } from "src/app/services/fetch-access-token.service";

@Component({
    selector: "mc-schedule-upgrade",
    templateUrl: "./schedule-upgrade.component.html",
    styleUrls: ["./schedule-upgrade.component.scss"]
})
export class ScheduleUpgradeComponent implements OnInit {
    stateStep1: StepState;
    stateStep2: StepState;
    stateStep3: StepState;
    activeIndex = 0;
    allSitesScheduled: boolean;
    atleastOneSiteScheduled: boolean;
    release;
    cloudId;
    customerList = [];
    showUpgrade=false;
    selectedcustomer;
    disableStep4 = false;
    releases;
    successOrFailureMessage = {}
    customerAndSiteList = {};
    dialogRef;

    sites = [];
    schedule: any;
    step2ErrorMessage = "";
    step1ErrorMessage = "";
    disableStep3 = true;

    isReschedule = true;

    @ViewChild("step1Form")
    releaseDetailForm: ReleaseDetailComponent;

    @ViewChild("step2form")
    scheduleForm: ScheduleSitesComponent;

    @ViewChild("step3form")
    confirmForm: ConfirmUpgradeComponent;

    // @ViewChild("step4form")
    // successFailureForm: SuccessFailureComponent;


    constructor(private cdref: ChangeDetectorRef,
        private upgradeSchedulerService: UpgradeSchedulerService,
        private matDialog: MatDialog,
        private tdLoadingService: TdLoadingService,
        private snackBar: MatSnackBar,
        private router: Router,
        private customerSiteService: CustomerAndSiteService,
        private tdDialogService:TdDialogService,
        private activatedRoute:ActivatedRoute,
        private fetchAccessToken:FetchAccessTokenService
    ) {

    }


    getAllSitesForSelctedCustomer(selectionObj:any) {
        this.tdLoadingService.register('releaseLoad');
         selectionObj = JSON.parse(selectionObj);
        let selectedcustDetails = selectionObj.customer;
        this.cloudId = selectedcustDetails.cloudId;
         let sites = this.customerAndSiteList[selectedcustDetails.customerId].sites.filter((s)=>{
            return s.platform == selectionObj.platform;
        });
        // this.customerSiteService.getAllSites(selectedcustDetails.customerId).subscribe((sites) => {

        //this.upgradeSchedulerService.getSitesevent.emit(this.sites);
        this.upgradeSchedulerService.getSchedule(selectedcustDetails.cloudId, this.release.releaseID).pipe(finalize(() => {
            this.tdLoadingService.resolve('releaseLoad');
        })).subscribe((data) => {
            if (data && data.length) {
                //this.upgradeSchedulerService.getSitesevent.emit()
                this.schedule = data;
                let tempSiteArr = [];
                data.forEach((site) => {
                    if (site.scheduledOn !== "") {
                        const tempTime = moment.utc(site.scheduledOn).toDate();
                        const localTime = moment(tempTime).local().format("YYYY-MM-DD HH:mm:ss");
                        site.scheduleType = "SCHEDULE";
                        site.date = localTime.slice(0, 10);
                        site.time = localTime.slice(11, 16);
                        site.siteId = site.siteId;
                        site.cloudId = site.cloudId;
                        site.scheduleId = site.id;
                        site.selectedForSchedule = true;
                        let tmpSite = sites.reduce((acc, currVal) => {
                            if (currVal.name == site.siteId) {
                                acc = site;
                                acc.name = currVal.name;
                                acc.purpose = currVal.purpose;
                                acc.isReschedule = true;

                            }
                            return acc;
                        }, {})
                        if (Object.keys(tmpSite).length != 0) {
                            tempSiteArr.push(tmpSite);
                        }

                    }
                });
                let tmpSiteArr1 = tempSiteArr;
                sites.forEach((site) => {
                    let found = false;
                    tempSiteArr.forEach((tmpSite) => {
                      if (site['name'] == tmpSite['name']) {
                            found = true;
                        }
                    });
                    if (!found) {
                        site.isReschedule = false;
                        tmpSiteArr1.push(site);
                    }
                })
                tempSiteArr = tmpSiteArr1;
                this.sites = tempSiteArr;
                this.isReschedule = true;
            } else {
                this.sites = sites;
                if (!this.sites.length) {
                    this.snackBar.open("This customer does not have any site", 'Ok');
                }
                this.isReschedule = false;
            }
        },(err) => {
            this.snackBar.open("Could not fetch schedules!Login and try again", 'Ok');
        })
        // });

    }
    setSelectedRelease(selectedRelease) {
        this.release=selectedRelease;
       this.scheduleForm.resetCustomer();
        this.sites = [];
    }

    // getAllCustomers() {
    //     this.customerSiteService.getAllCustomers().pipe(finalize(()=>{
    //         this.tdLoadingService.resolve('releaseLoad');
    //     })).subscribe((data) => {
    //         console.log(data);
    //         this.customerList = data;
    //     }, (err) => {
    //         this.snackBar.open("Could not fetch customers!Login and try again",'Ok');
    //     });
    // }

    getAllSites() {
        this.customerSiteService.getAllSites().pipe(finalize(() => {
            this.tdLoadingService.resolve('releaseLoad');
        })).subscribe((data) => {
            this.customerAndSiteList = data;
            this.upgradeSchedulerService.setCustomerAndSiteInfo( this.customerAndSiteList);
            for (let key in data) {
                this.customerList.push(data[key].customer);
            }
            this.customerList.sort(function(a, b) {
                var textA = a.customerName.toUpperCase();
                var textB = b.customerName.toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });
        })
    }

    initializeSteps(){
       
        this.activeIndex=0;
        this.scheduleForm.resetCustomer();
        this.release='';
        this.releaseDetailForm.resetRelease();
        this.sites = [];
        this.selectedcustomer = '';
        this.stateStep1=StepState.None;
        this.stateStep2=StepState.None;
        this.stateStep3=StepState.None;
    }



    ngOnInit(): void {      
        if(localStorage.getItem('isLoggedIn') === 'true'){
            if(!this.fetchAccessToken.loginSubscription){
              this.fetchAccessToken.refreshToken();             
            }
            this.tdLoadingService.register('releaseLoad');          
            this.upgradeSchedulerService.completedEvent.subscribe((event)=>{
                this.initializeSteps();
            })
            this.upgradeSchedulerService.getReleases().subscribe((releases) => {
                this.releases = releases;
                //this.getAllCustomers();
                this.upgradeSchedulerService.setAllReleases(this.releases);
                this.getAllSites();
            },(err) => {
                this.snackBar.open("Could not fetch releases!Login and try again", 'Ok');
            })
        }else{
            this.router.navigate(['/'])
        }       
    }


    formatUserTimeZoneToDisplay() {
        const timeUTC = moment.utc();
        const localTime = moment(new Date()).local();
        const diff = moment.duration(localTime.diff(timeUTC)).hours();
        return "UTC" + diff;
    }

    activeStep1Event() {
        this.step2ErrorMessage = "";
        this.stateStep1 = StepState.Complete;
       this.activeIndex = 1;
        this.cdref.detectChanges();
    }

    activeStep2Event() {
        this.stateStep1 = StepState.Complete;
        this.activeIndex = 2;
        this.updateSchedules();
        this.cdref.detectChanges();
    }

    activeStep4Event() {
        this.activeIndex = 3;
        this.cdref.detectChanges();
    }

    next() {
        this.step1ErrorMessage = "";
        this.step2ErrorMessage = "";

        if (this.activeIndex === 1) {
            if (!this.scheduleForm.continue()) {
                this.step2ErrorMessage = "* Please schedule upgrade for atleast one site";
            } else {
                this.disableStep3 = false;
                this.updateSchedules();
                if (this.atleastOneSiteScheduled) {
                    this.activeIndex++;
                } else {
                    this.step2ErrorMessage = "* Please schedule upgrade for atleast one site";
                }
                this.cdref.detectChanges();
            }

        } else {
            if (this.activeIndex === 0 && !this.release) {
                this.step1ErrorMessage = "* Please select a release";
            }
            this.activeIndex++;
        }
    }

    doesAllSitesScheduled(sites) {
        let scheduled = true;
        sites.forEach((site) => {
            if (!scheduled) {
                return scheduled;
            }
            scheduled = site.scheduleType === "NOW" || (site.date && site.time && site.selectedForSchedule);
        });
        return scheduled;
    }

    upgrade() {
        let httpCalls;
        this.tdLoadingService.register("releaseLoad");

        const schedules = this.confirmForm.sites.reduce((acc, site) => {
            if (site.selectedForSchedule && site.scheduleType !== "") {
                let scheduleOn = moment(new Date());

                if (site.scheduledOn !== "") {
                    scheduleOn = moment(site.date).add(parseInt(site.time.split(":")[0], 10) * 60
                        + parseInt(site.time.split(":")[1], 10), "m");

                }
                acc.push({
                    releaseId: this.release.releaseID,
                    cloudId: this.cloudId,
                    siteId:  site.name || site.supportSiteId,
                    scheduleId: site.scheduleId,
                    scheduledOn: moment(scheduleOn.utc()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                    isReschedule: site.isReschedule

                });
            }

            return acc;

        }, [])
        httpCalls = schedules.map((schedule) => this.upgradeSchedulerService.startSchedule(schedule));
        // if (!this.isReschedule) {
        //     /*
        //      * Call api for every site
        //      */
        //     httpCalls = schedules.map((schedule) => this.upgradeSchedulerService.addSchedule(schedule));

        // } else {
        //     httpCalls = schedules.map((schedule) => this.upgradeSchedulerService.updateSchedule(schedule));
        // }
        forkJoin(httpCalls).pipe(finalize(() => {
             this.stateStep3 = StepState.Complete;

            this.tdLoadingService.resolve("releaseLoad");
        }))
            .subscribe((responses: any) => {
                const failedSchedules =
                    responses.filter((response) => response.schedule !== undefined && response.error !== undefined);

                const successfulSchedules =
                    responses.filter((response) => response.schedule === undefined && response.error === undefined);

                this.tdLoadingService.resolve("schedules.load");
                if (failedSchedules.length === 0) {
                    //this.disableStep4 = true;
                    this.successOrFailureMessage = {
                        'isSuccess': true,
                        'text': "Software maintenance scheduled for your selected sites : "+successfulSchedules.map((s) =>
                        s.siteId).join(", ")
                    }

                } else {
                   let message = "Scheduling failed for: [" + failedSchedules.map((s) =>
                        s.schedule.siteId).join(", ") + "]";
                    if (successfulSchedules.length) {
                        message = message + " and successful for [" + successfulSchedules.map((s) =>
                            s.siteId).join(", ") + "]";
                    }
                    // this.snackBar.open(message, null, {
                    //     duration: 5000,
                    // });
                    this.successOrFailureMessage = {
                        'isSuccess': false,
                        'text': message
                    }
                }


                this.dialogRef = this.tdDialogService.open(SuccessFailureComponent, {
                    data:  this.successOrFailureMessage,
                    width: '500px'
                  });
            },(err) => {
                this.snackBar.open("Could not schedule upgrade !Login and try again", 'Ok');
            });

    }

    goBackToDashboard() {
        this.router.navigate(['/'])
    }

    private doesAtleastOneSiteScheduled(sites) {
        let scheduled = false;
        sites.forEach((site) => {
            scheduled = scheduled || site.scheduleType === "NOW" || (site.date && site.time && site.selectedForSchedule);
        });
        return scheduled;
    }

    private updateSchedules() {
        this.confirmForm.sites = this.scheduleForm.sites.filter((site) => {
            return site.selectedForSchedule
        })
        this.confirmForm.release = this.release;
        // this.isComplete = this.doesAllSitesScheduled(this.scheduleForm.sites);
        // this.stateStep2 = this.allSitesScheduled ? StepState.Complete : StepState.Required;
        this.atleastOneSiteScheduled = this.doesAtleastOneSiteScheduled(this.scheduleForm.sites);
        this.stateStep2 = this.atleastOneSiteScheduled ? StepState.Complete : StepState.Required;
    }
}
