<mat-toolbar class="mat-toolbar mat-toolbar-multiple-rows ng-star-inserted">
  <mat-toolbar-row class="mat-toolbar-row">
    <div flex="" layout="row" layout-align="start center" class="extra-pad-left">
      <span class="extra-pad-left-no-bc">
        <mat-icon class="material-icons mat-icon mat-icon-dashboard">
          dashboard
        </mat-icon>
        <span class="title-no-bc">Add/Edit Failure Reason and Category</span>
      </span>
      <span flex=""></span>
      <span class="push-left-sm mat-body-1" class="narrow-search-fields">
        <mat-form-field class="padding-5 fixed-width" >
          <input matInput  [matDatepicker]="picker1" (ngModelChange)="getJobDetails()" readonly [formControl]="formStartDateControl"
            [max]="formEndDateControl.value" >
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="padding-5 fixed-width" >
          <input matInput [matDatepicker]="picker2" (ngModelChange)="getJobDetails()" readonly [formControl]="formEndDateControl"
            [min]="formStartDateControl.value" [max]="dateToday">
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
      </span>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
