
<div layout="column">
    <mat-form-field [ngClass]="{'margin-top':release}">
        <mat-select placeholder="Select a Release" name="selectedRelease" [(ngModel)]="selectedRelease"
          (selectionChange)="getSelectedReleaseInformation(selectedRelease)">
          <mat-option *ngFor="let release of releases" [value]="release">
            {{release.releaseName}}
          </mat-option>
        </mat-select>
      </mat-form-field>


</div>

<div *ngIf="release">
<div class="tile">
    <p class="title">Release Summary</p>
    <div class="block" flex="100" layout="row" layout-align="start start">
        <div layout="column" flex="30">
            <p class="head">Platform</p>
            <p class="content">{{release.platform}}</p>
        </div>
        <div layout="column" flex="40">
            <p class="head">Available Software Updates</p>
            <p class="content">
                <li class="extra-pad" *ngFor="let component of release.components"> <span class="component">{{allProducts[component.name]}}:</span> {{component.version}}</li>
            </p>
        </div>
        <div layout="column" flex="30">
            <p class="head">Expected Downtime</p>
            <p class="content">{{release.downTime + ' hour'+ (release.downTime == 1? '': 's')}}</p>
        </div>
        <!-- <div layout="column" flex="30">
            <p class="head">Region Availability</p>
            <p class="content">{{release.regions? release.regions.join(', '): ''}}</p>
        </div> -->
    </div>
</div>

<div class="tile">
    <p class="title">Customer Impact</p>
    <div class="block" flex="100" layout="row" layout-align="start start">
        <p>
            <li class="content">
                {{release.userImpact}}
            </li>
        </p>
    </div>
</div>

<div class="tile">
    <p class="title">What's New</p>
    <div class="block" flex="100" layout="row" layout-align="start start">
        <p>
            <li class="content extra-pad" *ngFor="let component of release.components">
               <span class="component">{{allProducts[component.name]}}:</span> {{component.features[0].description}} <br>
            </li>
        </p>
    </div>
</div>
</div>