import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfig } from "../app.config";
import { Subscription, Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class FetchAccessTokenService {
  accessToken: string;
  accessTokenPreprod: string;
  expiresAt;
  public loginSubscription: Subscription;

  public emitLogIn = Observable.interval(15 * 60 * 1000);
  constructor(private httpClient: HttpClient, private appConfig: AppConfig) {}

  public setAccessToken(accessToken: string) {
    localStorage.setItem("accessTokenUpaasApi", accessToken);
  }

  public getAccessToken() {
    return localStorage.getItem("accessTokenUpaasApi");
  }

  public refreshToken() {
    let decodedString = atob(localStorage.getItem("up"));
    let dividingIndex = decodedString.indexOf("/");
    let username = decodedString.substring(0, dividingIndex);
    let pswd = decodedString.substring(dividingIndex + 1);
    //If token has expired already refresh the token
    if (Number(localStorage.getItem("expires_in")) < new Date().getTime()) {
      this.fetchAccessToken(username, pswd).subscribe((data: any) => {
        this.setAccessToken(data.access_token);
      });
    }

    //call the interval in 15 mins
    this.loginSubscription = this.emitLogIn.subscribe(() => {
      this.fetchAccessToken(username, pswd).subscribe((data: any) => {
        this.setAccessToken(data.access_token);
      });
    });
  }

  public fetchAccessToken(username, pswd) {
    let ssoUrl = this.appConfig.getConfig("ssoUrl");
    let payload = {
      username: username,
      password: pswd,
      audience: this.appConfig.getConfig("audienceParam"),
      client_id: this.appConfig.getConfig("clientID"),
      url: this.appConfig.getConfig("ssoUrlApiUrl")
    };
    return this.httpClient.post(ssoUrl, payload);
  }
}
