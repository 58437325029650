import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor() { }

  canActivate(activatedRouterSnapshot:ActivatedRouteSnapshot,state:RouterStateSnapshot){
     if(localStorage.getItem('isLoggedIn')){
       return true;
     }else{
      return false;
     }
  }
}
