<mat-card class="mat-card"
  *tdLoading="'jobLoad'; mode:'indeterminate'; type:'circle'; strategy:'overlay'; color:'primary'">
  <mat-card-title>
    <div layout="row" layout-align="start center">
      <span class="mat-title push-bottom-none">Job Details</span>
    </div>
  </mat-card-title>
  <mat-divider></mat-divider>
  <mat-card-content>
    <h4>Select the criteria for job search</h4>
    <mat-radio-group aria-label="Select an option" [(ngModel)]="searchOption" (change)="refreshView();">
      <mat-radio-button value="bySite">Search jobs by Site Id and Release</mat-radio-button>
      <mat-radio-button value="byJob" class="extra-pad-left">Search jobs by Job Id</mat-radio-button>
    </mat-radio-group>

    <form name="form" (ngSubmit)="searchForm.form.valid && onSubmit(searchForm)" #searchForm="ngForm" novalidate>
      <div layout="row" class="search-container">
        <div class="form-group container" *ngIf="searchOption == 'bySite'">
          <mat-form-field class="item">
            <input required name="siteId" ngModel #siteId="ngModel" matInput placeholder="Enter Site ID"
              class="form-control">
          </mat-form-field>
          <mat-form-field class="item">
            <mat-select #releaseId="ngModel" placeholder="Select a Release" name="selectedRelease"
              [(ngModel)]="selectedRelease">
              <mat-option *ngFor="let release of releases" [value]="release.releaseID">
                {{release.releaseName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="form-group container" *ngIf="searchOption == 'byJob'">
          <mat-form-field class="item-job">
            <input required name="jobId" #jobId="ngModel" matInput placeholder="Enter Job ID" class="form-control"
              ngModel>
          </mat-form-field>
          <mat-checkbox class="details-checkbox" name="isDetails" [(ngModel)]="isDetailsChecked">Details</mat-checkbox>
        </div>

        <div>
          <button [disabled]="searchForm.invalid" type="submit" mat-raised-button color="accent"
            class="mat-accent textUpper" *ngIf="searchOption" style="margin-top:5px">Submit</button>
        </div>
      </div>
    </form>
    <!-- <td-message *ngIf="showError"  sublabel="Incorrect username/password" color="warn" icon="error"></td-message> -->

    <mat-accordion *ngIf="allJobs.length">
      <mat-expansion-panel *ngFor="let job of allJobs ; let index = index" #matExpansionPanel>
        <mat-expansion-panel-header (click)="expandPanel(matExpansionPanel, $event,job,index)">
          <mat-panel-title>
            {{job.id}}
          </mat-panel-title>
          <mat-panel-description>
            <div class="job-description-grid">
              <span class="panel-grid-item"> {{statusArr[job.status]}} </span>
              <span class="panel-grid-item"> {{products[job.product]}} </span>
              <span class="panel-grid-item"> {{job.to_version}} </span>
              <span class="panel-grid-item">
                <mat-checkbox [(ngModel)]="job.isDetailsChecked">Details</mat-checkbox>
              </span>
            </div>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <mat-progress-bar mode="query" *ngIf="showLoader[index]">
          </mat-progress-bar>
          <div *ngIf="dataSourceForPhaseList[index]">
            <app-job-details-table [inputDataSource]="dataSourceForPhaseList[index]"></app-job-details-table>
          </div>
          <div *ngIf="showNoDataFoundMessage">
            <app-no-data-found noDataFoundMessage="No job details to display"></app-no-data-found>
          </div>
        </ng-template>
      </mat-expansion-panel>
    </mat-accordion>

    <div *ngIf="searchOption == 'byJob' && dataSourceForPhaseList &&   dataSourceForPhaseList.length">
      <app-job-details-table [inputDataSource]="dataSourceForPhaseList"></app-job-details-table>
    </div>

    <div *ngIf="showNoJobIdsMessage">
      <app-no-data-found noDataFoundMessage="No job info available"></app-no-data-found>
    </div>
  </mat-card-content>
</mat-card>
