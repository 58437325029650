import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import * as moment from 'moment';
import { GetJobDetailsService } from 'src/app/services/job-details.service';
import { finalize } from 'rxjs/operators';
import { FailureAnalysisService } from 'src/app/services/failure-analysis.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.css']
})
export class SearchBarComponent implements OnInit {
  endDate = new Date();
  startDate = new Date();
  dateToday = new Date();
  formStartDateControl: FormControl;
  formEndDateControl: FormControl;
  myControl = new FormControl();

  constructor(
    private snackBar: MatSnackBar,
    private getJobDetailsRepo:GetJobDetailsService,
    private failureService :FailureAnalysisService,
    private router:Router) {
    // By default the end date is -3 months of the start date
    this.startDate.setDate(this.startDate.getDate() - 7);
    this.formStartDateControl = new FormControl(this.startDate);
    this.formEndDateControl = new FormControl(new Date(this.endDate));
  }

  // this is to set the value of all
  // customers in this.customers which are the  values that are shown in drop down
  ngOnInit() {
    if(localStorage.getItem('isLoggedIn') === 'false'){
      this.router.navigate(['/'])
    }else{
      this.getJobDetails();
    }
      
  }


  getJobDetails(){
    this.failureService.fireLoaderOnApiCall.next();
     this.getJobDetailsRepo.getAllJobDetailsFromApi(moment(this.formStartDateControl.value).format('YYYY-MM-DD').toString(),
      moment(this.formEndDateControl.value).format('YYYY-MM-DD').toString()).pipe(finalize(()=>{
        this.getJobDetailsRepo.jobDetailsRecievedEvent.next();
      })).
      subscribe((response)=>{
           this.getJobDetailsRepo.setAllJobDetails(response);
      },()=>{
         this.snackBar.open('There was some problem in retrieving job details information','ok');
      })
  }
}
